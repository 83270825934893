import { UPDATE_ORDER_DETAIL } from "../actions/OrderDetail.action";
import { OrderDetail, defaultOrderDetail } from "../models/OrderDetail.model";
import { Action } from "../models/AppCommon.model";

const orderDetailReducer = (state: OrderDetail = defaultOrderDetail, action: Action): OrderDetail => {
    switch (action.type) {
        case UPDATE_ORDER_DETAIL:
            return { ...action.payload };
        default:
            return state;
    }
};

export default orderDetailReducer