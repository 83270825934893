import { Button, Card, Divider, Grid, Icon, Popup, Header, Input, Dropdown, Form } from 'semantic-ui-react';
import '../styles/OrderDetail.css';
import { OrderDetail, OrderDetailItem, OrderDetailProps, OrderStatus, PaymentValues, SideMenuEnum, calculatecommissionsPerOrder, defaultSideMenuState, getTotal, getPreTaxTotal } from '../models/OrderDetail.model';
import ConfirmationModal from '../modals/Confirmation.modal';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addOrder, deleteOrder, editOrder } from '../actions/Orders.action';
import CheckoutModal from '../modals/Checkout.modal';
import { clearOrderDetail, updateOrderDetail } from '../actions/OrderDetail.action';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '../models/Menu.model';
import { RootState } from '../reducers/root.reducer';
import { isAdmin, isEmptyArray, isManagerRole, setPrintContent } from '../models/AppCommon.model';
import { patternEnum, patternOptions } from '../models/Sale.model';
import { PrintComponent } from './Print';

function OrderDetailComponent({ orderDetailState, isSalePage, isEditOrder, setIsEditOrder, setActiveMenuItem }: OrderDetailProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInEmployee = useSelector((state: RootState) => state.loggedInEmployee);
  const discount = useSelector((state: RootState) => state.orderDetail.discount);
  const authState = useSelector((state: RootState) => state.auth);
  const printReceiptAfterCheckout = useSelector((state: RootState) => state.auth.settings.printReceiptAfterCheckout);

  const [confirmationModalState, setConfirmationModalState] = useState({
    modalOpen: false,
    modalType: "Delete Order Confirmation",
    modalContentMessage: "Are you sure you want to delete this order?"
  });

  const [checkoutModalState, setCheckoutModalState] = useState({
    modalOpen: false,
    modalType: "Checkout Order"
  });

  const [sideMenuState, setSideMenuState] = useState(defaultSideMenuState);

  useEffect(() => {
    dispatch(updateOrderDetail({
      ...orderDetailState,
      userId: authState.id ? authState.id : "",
      tax: authState.settings.tax
    }));
  }, [authState.id, authState.settings.tax]);

  const handleOrderItemDelete = (orderItem: OrderDetailItem) => {
    dispatch(updateOrderDetail({
      ...orderDetailState,
      items: orderDetailState.items.filter((item: OrderDetailItem) => item.id !== orderItem.id)
    }));
  }

  const handleDelete = () => {
    setConfirmationModalState(prevState => ({
      ...prevState,
      modalOpen: true
    }));
  }

  const handlePrint = (isBuildOrder = true) => {
    if (isBuildOrder && (orderDetailState.status === OrderStatus.none || orderDetailState.status === OrderStatus.open)) {
      buildOrder(OrderStatus.printed);
    }
    setPrintContent("receipt-print-content");
  };

  const handleSave = () => {
    buildOrder(OrderStatus.open);
  }

  const handleEdit = () => {
    navigate("/easy-manage/sale", { replace: true });
    setActiveMenuItem!(MenuItem.sale);
    setIsEditOrder(true);
  }

  const handleCheckout = () => {
    setCheckoutModalState(prevState => ({
      ...prevState,
      modalOpen: true
    }));
  }

  const handleCharge = (value: PaymentValues) => {
    if (isModifyExistingOrder()) {
      dispatch(editOrder({ ...orderDetailState, status: OrderStatus.close, cashAmount: value.cash, creditAmount: value.credit, giftCardAmount: value.giftCard, creditTips: value.creditTips }));
    } else {
      dispatch(addOrder({ ...orderDetailState, status: OrderStatus.close, cashAmount: value.cash, creditAmount: value.credit, giftCardAmount: value.giftCard, creditTips: value.creditTips, createTimestamp: new Date() }));
    }
    if (printReceiptAfterCheckout) {
      handlePrint(false);
    }
    clearOrderDetailData();
  }

  const handleNoClick = () => {
    clearConfirmationModal();
  }

  const handleYesClick = () => {
    if (isModifyExistingOrder()) {
      if (orderDetailState.status === OrderStatus.close) {
        dispatch(editOrder({ ...orderDetailState, status: OrderStatus.cancel }));
      } else {
        dispatch(deleteOrder(orderDetailState));
      }
    }
    clearOrderDetailData();
    clearConfirmationModal();
  }

  const handleSideMenuClick = () => {
    setSideMenuState(prevState => ({
      ...prevState,
      isOpen: true
    }));
  }

  const handleDiscountClick = () => {
    dispatch(updateOrderDetail({ ...orderDetailState, discount: sideMenuState.discount ? (sideMenuState.discountUnit === patternEnum.amount ? sideMenuState.discount : getPreTaxTotal(orderDetailState) * (sideMenuState.discount / 100)) : undefined }));
    clearSideMenuState();
  }

  const handleExtraChargeClick = () => {
    const extraChargeItem: OrderDetailItem | undefined = orderDetailState.items.find((item: OrderDetailItem) => item.id === SideMenuEnum.extraCharge);
    if (extraChargeItem) {
      dispatch(updateOrderDetail({
        ...orderDetailState,
        items: [
          ...orderDetailState.items.map((item: OrderDetailItem) => {
            if (item.id === SideMenuEnum.extraCharge) {
              return {
                ...item,
                itemPrice: sideMenuState.extraCharge
              }
            } else {
              return item;
            }
          })
        ]
      }));
    } else {
      dispatch(updateOrderDetail({
        ...orderDetailState,
        items: [
          ...orderDetailState.items,
          {
            id: SideMenuEnum.extraCharge,
            itemName: "Extra Charge",
            itemPrice: sideMenuState.extraCharge,
            quantity: 1
          }
        ]
      }));
    }
    clearSideMenuState();
  }

  const handleModifyDiscountUnit = (e: any, data: any) => {
    setSideMenuState(prevState => ({
      ...prevState,
      discountUnit: data.value
    }));
  }

  const handleModifyDiscount = (e: any) => {
    setSideMenuState(prevState => ({
      ...prevState,
      discount: Number(e.target.value)
    }));
  }

  const handleModifyExtraCharge = (e: any) => {
    setSideMenuState(prevState => ({
      ...prevState,
      extraCharge: Number(e.target.value)
    }));
  }

  const clearConfirmationModal = () => {
    setConfirmationModalState(prevState => ({
      ...prevState,
      modalOpen: false
    }));
  }

  const clearCheckoutnModal = () => {
    setCheckoutModalState(prevState => ({
      ...prevState,
      modalOpen: false
    }));
  }

  const clearOrderDetailData = () => {
    dispatch(clearOrderDetail(loggedInEmployee, authState));
    setIsEditOrder(false);
  }

  const clearSideMenuState = () => {
    setSideMenuState({
      ...defaultSideMenuState
    });
  }

  const showCancel = () => {
    return !isAdmin(loggedInEmployee) && (orderDetailState.status === OrderStatus.open || orderDetailState.status === OrderStatus.none || (orderDetailState.status === OrderStatus.close && isManagerRole(loggedInEmployee)));
  }

  const showEdit = () => {
    return !isAdmin(loggedInEmployee) && (!isSalePage && orderDetailState.status === OrderStatus.open);
  }

  const showCheckout = () => {
    return !isAdmin(loggedInEmployee) && (orderDetailState.status === OrderStatus.none || orderDetailState.status === OrderStatus.open || orderDetailState.status === OrderStatus.printed);
  }

  const isOrderDetailShow = () => {
    return (!isEmptyArray(orderDetailState.items) && isSalePage) && (orderDetailState.status === OrderStatus.open || orderDetailState.status === OrderStatus.none);
  }

  const isModifyExistingOrder = () => {
    return !isSalePage || isEditOrder;
  }

  const getcommissions = (orderDetailState: OrderDetail) => {
    return calculatecommissionsPerOrder(orderDetailState);
  }

  const buildOrder = (status: OrderStatus) => {
    if (isEditOrder || (!isSalePage && status === OrderStatus.printed)) {
      dispatch(editOrder({ ...orderDetailState, status: status }));
    } else {
      dispatch(addOrder({ ...orderDetailState, status: status, createTimestamp: new Date() }));
    }
    clearOrderDetailData();
  }

  return (
    <div className="order-detail-page__card">
      <Card className="order-detail-page__card">
        <Card.Content className="order-detail-page__item-content">
          {
            orderDetailState.items && orderDetailState.items.map((item: OrderDetailItem, index:number) => {
              if(item.isVariablePrice) {
                item.id = item.id! + index;
              }
              return <div key={item.id! + index}>
                <div className="order-detail-page__detail-block">
                  <div className="order-detail-page__detail-title">
                    <span className="order-detail-page__item-quantity">{item.quantity}X</span><strong>{item.itemName}</strong>
                  </div>
                  <div className="order-detail-page__detail-price">
                    <strong>{(item.itemPrice * item.quantity).toFixed(2)}</strong>
                    {isSalePage && <Button className="order-detail-page__detail-delete" size="mini" circular icon="trash" onClick={() => handleOrderItemDelete(item)} />}
                  </div>
                </div>
                <Divider />
              </div>
            })
          }
        </Card.Content>
        <Card.Content extra className="order-detail-page__item-content order-detail-page__total-content">
          <Grid>
            <Grid.Row className="order-detail-page__pedding-row">
              {isOrderDetailShow() && <Grid.Column width={8}>
                <Popup
                  className="order-detail-page__popup"
                  trigger={<Button icon className="order-detail-page__side-menu" onClick={() => handleSideMenuClick()}><Icon name="bars" /></Button>}
                  on="click"
                  open={sideMenuState.isOpen}
                  onClose={clearSideMenuState}
                  positionFixed
                >
                  <Grid centered celled>
                    <Grid.Row>
                      <Grid.Column textAlign="center" width={16}>
                        <Header as="h5">Discount</Header>
                        <Form>
                          <Form.Field>
                            <Input
                              action={
                                <Dropdown
                                  button
                                  basic
                                  floating
                                  options={patternOptions}
                                  defaultValue={sideMenuState.discountUnit}
                                  onChange={handleModifyDiscountUnit}
                                />
                              }
                              type="number"
                              value={sideMenuState.discount}
                              onChange={handleModifyDiscount}
                            />
                          </Form.Field>
                          <Button onClick={() => handleDiscountClick()}>Save</Button>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column textAlign="center" width={16}>
                        <Header as="h5">Extra Charge</Header>
                        <Form>
                          <Form.Field>
                            <Input
                              type="number"
                              value={sideMenuState.extraCharge}
                              onChange={handleModifyExtraCharge}
                            />
                          </Form.Field>
                          <Button onClick={() => handleExtraChargeClick()}>Save</Button>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Popup>
              </Grid.Column>}

              <Grid.Column width={isOrderDetailShow() ? 4 : 12} className="order-detail-page__text-align">
                <label>Sub Total: </label>
              </Grid.Column>
              <Grid.Column width={4}>
                <label>${getPreTaxTotal(orderDetailState).toFixed(2)}</label>
              </Grid.Column>
            </Grid.Row>

            {orderDetailState.discount && <Grid.Row className="order-detail-page__pedding-row">
              <Grid.Column width={12} className="order-detail-page__text-align">
                <label>Discount: </label>
              </Grid.Column>
              <Grid.Column width={4}>
                <label>-${discount?.toFixed(2)}</label>
              </Grid.Column>
            </Grid.Row>}

            <Grid.Row className="order-detail-page__pedding-row">
              {!isSalePage && <Grid.Column width={4} className="order-detail-page__text-align">
                <label>Tips: </label>
              </Grid.Column>}
              {!isSalePage && <Grid.Column width={4}>
                <label>${orderDetailState.creditTips}</label>
              </Grid.Column>}

              <Grid.Column width={isSalePage ? 12 : 4} className="order-detail-page__text-align">
                <label>Tax: </label>
              </Grid.Column>
              <Grid.Column width={4}>
                <label>{(orderDetailState.tax * 100)}</label><label>%</label>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="order-detail-page__pedding-row">
              {!isSalePage && <Grid.Column width={4} className="order-detail-page__text-align">
                <label>commissions: </label>
              </Grid.Column>}
              {!isSalePage && <Grid.Column width={4}>
                <label>${getcommissions(orderDetailState).toFixed(2)}</label>
              </Grid.Column>}

              <Grid.Column width={isSalePage ? 12 : 4} className="order-detail-page__text-align">
                <strong>Total: </strong>
              </Grid.Column>
              <Grid.Column width={4}>
                <strong>${getTotal(orderDetailState)}</strong>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
        <Card.Content extra className="order-detail-page__button-content">
          {orderDetailState.items.length > 0 && <div className="ui four buttons order-detail-page__button-bar">
            {showCancel() && <Button className="order-detail-page__button-font-size" color="red" onClick={() => handleDelete()}>
              <Icon name="cancel" /> Cancel
            </Button>}
            <Button className="order-detail-page__button-font-size" color="blue" onClick={() => handlePrint()}>
              <Icon name="print" /> Print
            </Button>
            <div style={{ display: "none" }}><PrintComponent /></div>
            {isSalePage && <Button className="order-detail-page__button-font-size" color="orange" onClick={() => handleSave()}>
              <Icon name="save" /> Save
            </Button>}
            {showEdit() && <Button className="order-detail-page__button-font-size" color="orange" onClick={() => handleEdit()}>
              <Icon name="edit" /> Edit
            </Button>}
            {showCheckout() && <Button className="order-detail-page__button-font-size" color="teal" onClick={() => handleCheckout()}>
              <Icon name="clipboard check" /> Checkout
            </Button>}
          </div>}
        </Card.Content>
      </Card>

      <ConfirmationModal
        confirmationState={confirmationModalState}
        handleNoClick={handleNoClick}
        handleYesClick={handleYesClick}
      />

      <CheckoutModal
        checkoutState={checkoutModalState}
        orderDetailState={orderDetailState}
        onCharge={handleCharge}
        clearCheckoutnModal={clearCheckoutnModal}
      />
    </div>
  );
}

export default OrderDetailComponent;