import { dateFormat, formatDate } from "./AppCommon.model";
import { Employee } from "./Employees.model";
import { OrderDetail } from "./OrderDetail.model";
import * as XLSX from 'xlsx';

export interface ReportsState extends Employee {
    orders: OrderDetail[];
}

export interface ReportsTotalCountMap {
    hours: number[];
    days: number[];
    orders: number[];
    commissions: number[];
    creditTips: number[];
    cashAmount: number[];
    creditAmount: number[];
    giftCardAmount: number[];
    totalSaleAmount: number[];
}

export interface ReportsExport {
    name: string;
    hours: number;
    days: number;
    orders: number;
    commissions: number;
    creditTips: number;
    cash: number;
    creditCard: number;
    giftCard: number;
    overallSale: number;
}

export interface ReportsCharProps {
    reportsCharState: ReportsCharState;
}

export interface ReportsCharState {
    categories: string[];
    series: CharSeries[];
}

export interface CharSeries {
    name: string;
    data: number[];
}

export const defaultReportsTotalCountMap: ReportsTotalCountMap = {
    hours: [],
    days: [],
    orders: [],
    commissions: [],
    creditTips: [],
    cashAmount: [],
    creditAmount: [],
    giftCardAmount: [],
    totalSaleAmount: []
}

export enum SwitchType {
    chart = "chart",
    table = "table"
}

export function groupByTimestamp(orders: OrderDetail[]): any {
    return orders.reduce((result, currentOrder) => {
        const timestamp: string = formatDate(currentOrder.createTimestamp!, dateFormat);
        if (!result[timestamp]) {
            result[timestamp] = [];
        }
        result[timestamp].push(currentOrder);
        return result;
    }, {} as any);
}

export function exportToExcel(data: any, filename: string): void {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, filename);
}