import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/root.reducer";
import { OrderDetailItem, getPreTaxTotal, getTotal } from "../models/OrderDetail.model";
import { dateTimeFormat, formatDate } from "../models/AppCommon.model";

export const PrintComponent = () => {
    const orderDetail = useSelector((state: RootState) => state.orderDetail);
    const employees = useSelector((state: RootState) => state.employees);
    const auth = useSelector((state: RootState) => state.auth);

    return (
        <div id="receipt-print-content">
            <div style={{ textAlign: "center" }}>
                <div style={{ fontSize: "x-large", fontWeight: "bold" }}>{auth.businessName}</div>
                <div style={{ fontSize: "large", fontWeight: "bold" }}>Tel: {auth.phone}</div>
                <div style={{ fontSize: "large", fontWeight: "bold" }}>Employee: {(orderDetail.employeeId ? employees.find(employee => employee.id === orderDetail.employeeId) : orderDetail.employee)?.name}</div>
                <div>Date: {formatDate(orderDetail.createTimestamp ? orderDetail.createTimestamp : new Date(), dateTimeFormat)}</div>
                <br />
                <div style={{ borderBottom: "1px solid", marginBottom: "10px" }}></div>
                {
                    orderDetail.items && orderDetail.items.map((item: OrderDetailItem) => {
                        return <div key={item.id}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <strong style={{ fontSize: "12px", marginRight: "10px" }}>{item.quantity}X</strong><strong>{item.itemReceiptName ? item.itemReceiptName : item.itemName}</strong>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>{(item.itemPrice * item.quantity).toFixed(2)}</strong>
                                </div>
                            </div>
                        </div>
                    })
                }
                <div style={{ borderBottom: "1px solid", marginTop: "10px", marginBottom: "10px" }}></div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "flex-start" }}>
                        <span style={{ marginRight: "10px" }}>Sub Total:</span>
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                        <strong>${getPreTaxTotal(orderDetail).toFixed(2)}</strong>
                    </div>
                </div>
                {orderDetail.discount && <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "flex-start" }}>
                        <span style={{ marginRight: "10px" }}>Discount:</span>
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                        <strong>-${orderDetail.discount?.toFixed(2)}</strong>
                    </div>
                </div>}
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "flex-start" }}>
                        <span style={{ marginRight: "10px" }}>Tax:</span>
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                        <strong>{(orderDetail.tax * 100)}%</strong>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ justifyContent: "flex-start" }}>
                        <span style={{ marginRight: "10px" }}>Total:</span>
                    </div>
                    <div style={{ justifyContent: "flex-end" }}>
                        <strong>${getTotal(orderDetail).toFixed(2)}</strong>
                    </div>
                </div>
                <br />
                <div>THANKS FOR VISITING</div>
            </div>
        </div>
    );
};