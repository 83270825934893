import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { ReportsCharProps } from '../models/Reports.model';

const ReportsChart = ({ reportsCharState }: ReportsCharProps) => {
    const options = {
        title: {
            text: "Sales Chart",
        },
        xAxis: {
            categories: reportsCharState.categories,
        },
        yAxis: {
            title: {
                text: "Dollar($)",
            },
        },
        series: reportsCharState.series
    };

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default ReportsChart;
