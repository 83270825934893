import { Employee } from "./Employees.model";
import { Action, ResponceSetup } from "./AppCommon.model";
import { SettingState, defaultSettingState } from "./Settings.model";

export interface AuthAction extends Action {
    type: string;
    payload: AuthState;
}

export interface AuthState {
    id?: string;
    email: string;
    phone: string;
    businessName: string;
    settings: SettingState;
    isLoggedIn?: boolean;
    isDemo?: boolean;
    isSubscribed?: boolean;
}

export interface AuthStateValue {
    auth: AuthState;
    apiResponseSetup: ResponceSetup;
}

export interface AuthLocalState extends AuthState {
    password: string;
    confirmPassword: string;
    isRegister: boolean;
    emailValidate: ValidationType;
    passwordValidate: ValidationType;
    confirmPasswordValidate: ValidationType;
    phoneValidate: ValidationType;
    loginValidate: ValidationType;
    registerValidate: ValidationType;
    verifyEmailValidate: ValidationType;
}

export interface ValidationType {
    errorMessage: string;
    isNotValid: boolean;
}

export interface AuthActionCreators {
    updateUserInfo(currentState: AuthState): AuthAction;
    updateUserRef(authState: AuthState): void;
    getUserById(user: any): any;
    addEmployee(employee: Employee): any;
}

export const initialAuthState: AuthState = {
    email: "",
    phone: "",
    businessName: "",
    settings: defaultSettingState,
    isLoggedIn: false,
    isDemo: false,
    isSubscribed: false
};

export const phoneNumberPattern: any = /^(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}$/;
export const emailPattern: any = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;

export const errorMessages = {
    emailErrorMessage: "Please provide a valid email that matches format (e.g. info@sample.com).",
    passwordErrorMessage: "Your password must be at least 8 characters long.",
    confirmPasswordErrorMessage: "Password and Confirm Password does not match.",
    phoneErrorMessage: "Please provide a valid phone number that matches format (e.g. 123-456-7890 or 1234567890).",
    loginErrorMessage: "Invalid username or password.",
    registerErrorMessage: "Register Failed.",
    verifyEmailErrorMessage: "Please check your inbox to verify your email address then try login again."
}