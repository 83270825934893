import { Action, ResponceSetup, defaultResponceSetup } from "../models/AppCommon.model";
import { UPDATE_MESSAGE } from "../actions/APIResponse.action";

const apiResponseReducer = (state: ResponceSetup = defaultResponceSetup, action: Action): ResponceSetup => {
    switch (action.type) {
        case UPDATE_MESSAGE:
            return { ...action.payload };
        default:
            return state;
    }
};

export default apiResponseReducer