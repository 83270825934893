import { useNavigate } from 'react-router-dom';
import '../styles/Info.css';
import { Button, Grid, GridRow, Icon } from 'semantic-ui-react';

function InfoComponent() {
  const navigate = useNavigate();

  const handleInfoButtonClick = () => {
    navigate("/login");
  }

  return (
    <div>
      <Grid>
        <GridRow className="info-top-container">
          <img className="info-background-image" src={"/static/images/info-background.jpeg"} />
          <div className="info-button__block">
            <Button className="info-button" basic color="black" onClick={() => handleInfoButtonClick()}>
              Click to Start A Demo
            </Button>
            <div className="info-demo-block">
              <div className="info-demo-text"><b>Demo Email:</b> easy.manage.info@gmail.com</div>
              <div className="info-demo-text"><b>Demo Password:</b> 12345678</div>
              <div className="info-demo-text"><b>Demo Admin Pin#:</b> 1234</div>
            </div>
          </div>
        </GridRow>
      </Grid>
      <Grid>
        <GridRow className="info-description-row">
          <Grid.Column className="info-description-column-width">
            <img className="info-description-image" src={"/static/images/nail-salon.jpg"} />
          </Grid.Column>
          <Grid.Column className="info-description-column-width">
            <div className="info-description-text-block">
              <div className="info-description-title">Welcome to Easy Manage – Elevating Salon Management to a New Standard!</div>
              <div className="info-description-text">Are you tired of the hassle that comes with managing appointments, processing orders, creating sales categories and items, and tracking employee performance in your salon? Look no further! Easy Manage is the ultimate solution designed to streamline salon operations, boost efficiency, and elevate your business to new heights.</div>
            </div>
          </Grid.Column>
        </GridRow>

        <GridRow className="info-description-row">
          <Grid.Column className="info-description-column-width info-description-display-in-large-screen">
            <div className="info-description-text-block">
              <div className="info-description-title">Create Sale Categories and Items:</div>
              <div className="info-description-text">Easy Manage allows you to effortlessly create sale categories and items, enabling users to conveniently select items when creating orders.</div>
            </div>
          </Grid.Column>
          <Grid.Column className="info-description-column-width">
            <img className="info-description-image" src={"/static/images/sale.png"} />
          </Grid.Column>
          <Grid.Column className="info-description-column-width info-description-display-in-small-screen">
            <div className="info-description-text-block">
              <div className="info-description-title">Create Sale Categories and Items:</div>
              <div className="info-description-text">Easy Manage allows you to effortlessly create sale categories and items, enabling users to conveniently select items when creating orders.</div>
            </div>
          </Grid.Column>
        </GridRow>

        <GridRow className="info-description-row">
          <Grid.Column className="info-description-column-width">
            <img className="info-description-image" src={"/static/images/create-order.png"} />
          </Grid.Column>
          <Grid.Column className="info-description-column-width">
            <div className="info-description-text-block">
              <div className="info-description-title">Effortless Order Creation:</div>
              <div className="info-description-text">Our user-friendly interface allows employees or cashiers to create orders with just a few clicks. Say goodbye to manual paperwork and enjoy a seamless order creation process.</div>
            </div>
          </Grid.Column>
        </GridRow>

        <GridRow className="info-description-row">
          <Grid.Column className="info-description-column-width info-description-display-in-large-screen">
            <div className="info-description-text-block">
              <div className="info-description-title">Print on the Go:</div>
              <div className="info-description-text">Need a physical copy of the order details? Easy Manage lets you print order receipts effortlessly, providing a professional touch to your salon operations.</div>
            </div>
          </Grid.Column>
          <Grid.Column className="info-description-column-width">
            <img className="info-description-image" src={"/static/images/receipt.png"} />
          </Grid.Column>
          <Grid.Column className="info-description-column-width info-description-display-in-small-screen">
            <div className="info-description-text-block">
              <div className="info-description-title">Print on the Go:</div>
              <div className="info-description-text">Need a physical copy of the order details? Easy Manage lets you print order receipts effortlessly, providing a professional touch to your salon operations.</div>
            </div>
          </Grid.Column>
        </GridRow>

        <GridRow className="info-description-row">
          <Grid.Column className="info-description-column-width">
            <img className="info-description-image" src={"/static/images/report.png"} />
          </Grid.Column>
          <Grid.Column className="info-description-column-width">
            <div className="info-description-text-block">
              <div className="info-description-title">Commission Calculation:</div>
              <div className="info-description-text">Easy Manage automatically calculates commissions for each order, making it easy for you to compensate your employees fairly and transparently.</div>
            </div>
          </Grid.Column>
        </GridRow>

        <GridRow className="info-description-row">
          <Grid.Column className="info-description-column-width info-description-display-in-large-screen">
            <div className="info-description-text-block">
              <div className="info-description-title">Employee Time Tracking:</div>
              <div className="info-description-text">Keep track of your employees' working hours and days effortlessly. Easy Manage provides accurate data for payroll, ensuring your team is compensated accurately and on time.</div>
            </div>
          </Grid.Column>
          <Grid.Column className="info-description-column-width">
            <img className="info-description-image" src={"/static/images/print-report.png"} />
          </Grid.Column>
          <Grid.Column className="info-description-column-width info-description-display-in-small-screen">
            <div className="info-description-text-block">
              <div className="info-description-title">Employee Time Tracking:</div>
              <div className="info-description-text">Keep track of your employees' working hours and days effortlessly. Easy Manage provides accurate data for payroll, ensuring your team is compensated accurately and on time.</div>
            </div>
          </Grid.Column>
        </GridRow>

        <GridRow className="info-description-row">
          <Grid.Column className="info-description-column-width">
            <img className="info-description-image" src={"/static/images/sales-chart.png"} />
          </Grid.Column>
          <Grid.Column className="info-description-column-width">
            <div className="info-description-text-block">
              <div className="info-description-title">Comprehensive Reporting:</div>
              <div className="info-description-text">Unlock the power of data with our robust reporting feature. Gain insights into your business performance, track sales trends, and make informed decisions to drive success.</div>
            </div>
          </Grid.Column>
        </GridRow>

        <GridRow>
          <Grid.Column width={5}></Grid.Column>
          <Grid.Column width={6}>
            <div className="info-contact-block">
              <div className="info-description-title">Have questions or need assistance? Contact our support team.</div>
              <div className="info-description-text"><Icon name="mail" /> easy.manage.info@gmail.com</div>
              <div className="info-description-text"><Icon name="phone" /> 347-438-5805</div>
              <img className="info-description-image" src={"/static/images/wechat.jpg"} />
            </div>
          </Grid.Column>
          <Grid.Column width={5}></Grid.Column>
        </GridRow>
      </Grid>
    </div>
  );
}

export default InfoComponent;