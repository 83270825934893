import { combineReducers } from "redux";
import authReducer from "./Auth.reducer";
import categoryReducer from "./Category.reducer";
import orderReducer from "./Orders.reducer";
import orderDetailReducer from "./OrderDetail.reducer";
import employeeReducer from "./Employees.reducer";
import loggedInEmployeeReducer from "./LoggedInEmployee.reducer";
import clockStatusReducer from "./ClockStatus.reducer";
import apiResponseReducer from "./APIResponse.reducer";
import filterReducer from "./Filter.reducer";
import loaderReducer from "./Loader.reducer";

const rootReducer = combineReducers({
    auth: authReducer,
    categories: categoryReducer,
    orders: orderReducer,
    orderDetail: orderDetailReducer,
    employees: employeeReducer,
    loggedInEmployee: loggedInEmployeeReducer,
    clockStatus: clockStatusReducer,
    apiResponseSetup: apiResponseReducer,
    filter: filterReducer,
    loaderState: loaderReducer,
});

export default rootReducer
export type RootState = ReturnType<typeof rootReducer>