import { Action } from "../models/AppCommon.model";
import { Employee, defaultEmployee } from "../models/Employees.model";

// Action types
export const UPDATE_LOGGED_IN_EMPLOYEE = "UPDATE_LOGGED_IN_EMPLOYEE";

// Action creator
export function updateLoggedInEmployee(employee: Employee): Action {
    return {
        type: UPDATE_LOGGED_IN_EMPLOYEE,
        payload: { ...employee }
    }
}

export function clearLoggedInEmployee(): Action {
    return {
        type: UPDATE_LOGGED_IN_EMPLOYEE,
        payload: {...defaultEmployee}
    }
}