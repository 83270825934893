import { Action, FilterState } from "../models/AppCommon.model";

// Action types
export const UPDATE_FILTER = "UPDATE_FILTER";

// Action creator
export function setFilter(value: FilterState): Action {
    return {
        type: UPDATE_FILTER,
        payload: { ...value }
    }
}