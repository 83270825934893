import React, { useEffect, useState, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Menu, Icon, Image, Message, Segment, Dimmer, Loader } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { RootState } from '../reducers/root.reducer';
import { MenuItem, MenuProps } from '../models/Menu.model';
import logo from '../images/menu logo.png';
import '../styles/Menu.css';
import { getCategories } from '../actions/Sale.action';
import { clearOrderDetail } from '../actions/OrderDetail.action';
import { getEmployees } from '../actions/Employees.action';
import { FirebaseDatabaseNames, SubscriptionStatus, calculateLastClockTime, contactEmail, contactPhone, filterEmployeeByActive, getDefaultFilters, internetCheck, isAdmin } from '../models/AppCommon.model';
import PinPadModal from '../modals/PinPad.modal';
import { clearLoggedInEmployee, updateLoggedInEmployee } from '../actions/LoggedInEmployee.action';
import { ClockTime, Employee } from '../models/Employees.model';
import { setClockStatus } from '../actions/ClockStatus.action';
import { setFilter } from '../actions/Filter.action';
import { db } from '../firebase/firebaseService';
import { decreaseLoader, increaseLoader } from '../actions/Loader.action';
import { updateUserRef } from '../actions/Auth.action';
import { clearMessage, updateErrorMessage } from '../actions/APIResponse.action';

function MenuComponent({ activeMenuItem, setActiveMenuItem, pageInitialLoad, setPageInitialLoad, setIsEditOrder }: MenuProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuSelectData = (state: RootState) => ({
    orderDetailState: state.orderDetail,
    loggedInEmployee: state.loggedInEmployee,
    employees: state.employees,
    clockStatus: state.clockStatus,
    authState: state.auth,
    apiResponseSetup: state.apiResponseSetup,
    loaderState: state.loaderState
  });
  const { loggedInEmployee, employees, clockStatus, authState, apiResponseSetup, loaderState } = useSelector(menuSelectData, shallowEqual);

  const [pinPadModalState, setPinPadModalState] = useState({
    modalOpen: false,
    modalType: "",
    allowClose: false,
    isInvalidPin: false
  });

  const lastInteractionTime = useRef(Date.now());
  const loggedInEmployeeId = useRef(loggedInEmployee.id);
  const enteredPin = useRef("");
  const isOnPinEntered = useRef(false);

  // Event handler to update lastInteractionTime when user interacts with page
  const handleInteraction = () => {
    lastInteractionTime.current = Date.now();
  };

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function() {
        window.history.pushState(null, "", window.location.href);
    };
    
    if (!loggedInEmployee.id) {
      setPinPadModalState(prevState => ({
        ...prevState,
        modalOpen: true
      }));
    }

    // Add event listeners for user interactions
    document.addEventListener('mousemove', handleInteraction);
    document.addEventListener('keydown', handleInteraction);
    document.addEventListener('click', handleInteraction);
    document.addEventListener('scroll', handleInteraction);
    document.querySelector('.container-height-scroll-bar')?.addEventListener('scroll', handleInteraction);
    document.addEventListener("wheel", handleInteraction);
    document.addEventListener("touchstart", handleInteraction);

    // Use setInterval to update the timer every 1000ms (1 second)
    const intervalId = setInterval(() => {
      const timeSinceLastInteraction = Date.now() - lastInteractionTime.current;
      if (timeSinceLastInteraction >= authState.settings.autoLogoutTime && loggedInEmployeeId.current) {
        handleLogout();
      }
    }, 1000);

    // Clean up event listeners and interval on component unmount
    return () => {
      document.removeEventListener('mousemove', handleInteraction);
      document.removeEventListener('keydown', handleInteraction);
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('scroll', handleInteraction);
      document.querySelector('.container-height-scroll-bar')?.removeEventListener('scroll', handleInteraction);
      document.removeEventListener("wheel", handleInteraction);
      document.removeEventListener("touchstart", handleInteraction);
      clearInterval(intervalId);
    };
  }, []);


  useEffect(() => {
    if (isOnPinEntered.current) {
      const clockStatus: any = {};
      employees.forEach((employee: Employee) => {
        const lastClockTime: ClockTime = calculateLastClockTime(employee);
        if ((lastClockTime.clockInTime && !lastClockTime.clockOutTime) || (lastClockTime.clockInTime && lastClockTime.clockOutTime && (lastClockTime.clockInTime.getTime() - lastClockTime.clockOutTime.getTime()) > 0)) {
          dispatch(setClockStatus({
            [employee.id!]: true
          }));
          clockStatus[employee.id!] = true;
        } else {
          dispatch(setClockStatus({
            [employee.id!]: false
          }));
          clockStatus[employee.id!] = false;
        }
      });
      const currentSelectedEmployee = filterEmployeeByActive(employees).find(employee => employee.pin === enteredPin.current);
      if (currentSelectedEmployee) {
        dispatch(updateLoggedInEmployee(currentSelectedEmployee));
        loggedInEmployeeId.current = currentSelectedEmployee.id;
        dispatch(clearOrderDetail(currentSelectedEmployee, authState));
        //handle navigation
        if (clockStatus[currentSelectedEmployee.id!]) {
          handleMenuItemClick(MenuItem.sale, false);
        } else if (activeMenuItem !== MenuItem.employees) {
          handleMenuItemClick(MenuItem.employees, false);
        }
      } else {
        setPinPadModalState(prevState => ({
          ...prevState,
          modalOpen: true,
          isInvalidPin: true
        }));
      }
      isOnPinEntered.current = false;
    }
  }, [employees]);

  const isLoggedInEmployeeClockedIn = () => {
    if (loggedInEmployee.id && clockStatus[loggedInEmployee.id] || isAdmin(loggedInEmployee)) {
      return true;
    }
    return false;
  }

  const handleMenuItemClick = (value: MenuItem, updateOrderDetail = true) => {
    if (!pageInitialLoad[value]) {
      setPageInitialLoad({
        ...pageInitialLoad,
        [value]: true
      });
      switch (value) {
        case MenuItem.sale:
          dispatch(getCategories(authState));
          break;
      }
    }
    if ((value === MenuItem.sale || value === MenuItem.orders) && updateOrderDetail) {
      dispatch(clearOrderDetail(loggedInEmployee, authState));
      setIsEditOrder(false);
    }
    setActiveMenuItem(value);
    navigate(value, { replace: true });
  }

  const handleLogout = () => {
    loggedInEmployeeId.current = "";
    dispatch(clearLoggedInEmployee());
    if (activeMenuItem !== MenuItem.employees) {
      handleMenuItemClick(MenuItem.employees);
    }
    setPinPadModalState(prevState => ({
      ...prevState,
      modalOpen: true
    }));
  }

  const onPinEntered = (pin: string) => {
    dispatch(clearMessage());
    if (!authState.isDemo) {
      internetCheck(dispatch);
      db.collection(FirebaseDatabaseNames.stripCustomers)
        .doc(authState.id)
        .collection(FirebaseDatabaseNames.subscriptions)
        .get()
        .then(snapshot => {
          // In this implementation we only expect one active or trialing subscription to exist.
          if (!snapshot.empty) {
            const doc = snapshot.docs[0];
            const docData = doc.data();
            if (docData && (docData.status === SubscriptionStatus.active || docData.status === SubscriptionStatus.trialing)) {
              if (!authState.isSubscribed) {
                dispatch(updateUserRef({ ...authState, isSubscribed: true, isDemo: false }, true));
              }
              afterPinEntered(pin);
            } else {
              dispatch(updateUserRef({ ...authState, isSubscribed: false, isDemo: false }, false));
              clearPinPadModal();
              dispatch(updateErrorMessage("Payment Error! Please Go to 'Subscription' to Check Your Payment Activities or Contect Customer Support at (347)-438-5805."));
              navigate("/easy-manage/settings");
            }
          } else if (authState.isSubscribed) {
            afterPinEntered(pin);
          } else {
            clearPinPadModal();
            dispatch(increaseLoader());
            handleSubscription();
          }
        })
        .catch(error => {
          console["error"]("Getting Subscription Details Failed", error);
        });
    } else {
      afterPinEntered(pin);
    }
  }

  const handleSubscription = async () => {
    const docRef = await db
      .collection(FirebaseDatabaseNames.stripCustomers)
      .doc(authState.id)
      .collection(FirebaseDatabaseNames.stripCheckoutSession)
      .add({
        price: "price_1P62pgHJwA3j05yd9vsuYQBV",
        trial_period_days: 30,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
      });
    // Wait for the CheckoutSession to get attached by the extension
    docRef.onSnapshot((snap: any) => {
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        window.location.assign(url);
        dispatch(decreaseLoader());
      }
    });
  }

  const afterPinEntered = (pin: string) => {
    dispatch(getEmployees(authState));
    dispatch(setFilter(getDefaultFilters()));
    setIsEditOrder(false);
    enteredPin.current = pin;
    isOnPinEntered.current = true;
    clearPinPadModal();
  }

  const clearPinPadModal = () => {
    setPinPadModalState(prevState => ({
      ...prevState,
      modalOpen: false,
      isInvalidPin: false
    }));
  }

  return (
    <div>
      {loaderState > 0 && <Dimmer inverted active>
        <Loader inverted>Loading</Loader>
      </Dimmer>}

      <Segment className="menu-page__business-info" size={"mini"}>
        <div><b>Contact Us:</b>&nbsp; &nbsp; {contactEmail}&nbsp; &nbsp; {contactPhone}</div>
      </Segment>
      <Menu className="menu-page__menu-container" color="teal" pointing secondary size="huge" widths={8}>
        <Menu.Item>
          <Image src={logo} size="small" />
        </Menu.Item>

        {isLoggedInEmployeeClockedIn() && <Menu.Item
          name="Sale"
          active={activeMenuItem === MenuItem.sale}
          onClick={() => handleMenuItemClick(MenuItem.sale)}
        >
          <Icon name="shop" />
          Sale
        </Menu.Item>}

        {isLoggedInEmployeeClockedIn() && <Menu.Item
          name="Orders"
          active={activeMenuItem === MenuItem.orders}
          onClick={() => handleMenuItemClick(MenuItem.orders)}
        >
          <Icon name="unordered list" />
          Orders
        </Menu.Item>}

        <Menu.Item
          name="Employees"
          active={activeMenuItem === MenuItem.employees}
          onClick={() => handleMenuItemClick(MenuItem.employees)}
        >
          <Icon name="users" />
          Employees
        </Menu.Item>

        <Menu.Item
          name="Reports"
          active={activeMenuItem === MenuItem.reports}
          onClick={() => handleMenuItemClick(MenuItem.reports)}
        >
          <Icon name="chart line" />
          Reports
        </Menu.Item>
        {isAdmin(loggedInEmployee) && <Menu.Item
          name="Settings"
          active={activeMenuItem === MenuItem.settings}
          onClick={() => handleMenuItemClick(MenuItem.settings)}
        >
          <Icon name="setting" />
          Settings
        </Menu.Item>}

        <Menu.Menu className="menu-page__right-menu" position="right">
          <Menu.Item
            className="menu-page__right-menu-item"
            onClick={() => handleLogout()}
          >
            <Icon name="sign-out" />
            <span>Welcome, {loggedInEmployee.name}</span>
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      {apiResponseSetup.showMessages && <Message color={apiResponseSetup.isErrorMessage ? "red" : "green"}>{apiResponseSetup.message}</Message>}

      <Outlet />

      <PinPadModal
        pinPadModalState={pinPadModalState}
        setPinPadModalState={setPinPadModalState}
        clearPinPadModal={clearPinPadModal}
        onPinEntered={onPinEntered}
      />
    </div >
  );
}

export default MenuComponent;
