import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducers/root.reducer";
import { FilterState, dateFormat, formatDate, isManagerRole } from "../models/AppCommon.model";
import { ReportsExport } from "../models/Reports.model";

export const PrintReportComponent = ({ reportsExportData }: any) => {
    const filterState: FilterState = useSelector((state: RootState) => state.filter);
    const loggedInEmployee = useSelector((state: RootState) => state.loggedInEmployee);

    const getDateString = () => {
        if (!filterState.startDate && !filterState.endDate) {
            return "All Time";
        } else if ((filterState.startDate && formatDate(filterState.startDate, dateFormat)) === (filterState.endDate && formatDate(filterState.endDate, dateFormat))) {
            return `${formatDate(filterState.startDate!, dateFormat)}`;
        } else {
            return `${formatDate(filterState.startDate!, dateFormat)} - ${formatDate(filterState.endDate!, dateFormat)}`
        }
    }

    return (
        <div id="report-print-content">
            <div style={{ textAlign: "center" }}>
                <div style={{ fontSize: "x-large", fontWeight: "bold" }}>Report</div>
                <div style={{ fontSize: "large" }}>Date: {getDateString()}</div>
                <div style={{ borderBottom: "1px solid", marginTop: "10px", marginBottom: "10px" }}></div>
                {reportsExportData.current.map((exportData: ReportsExport, index: number) => {
                    if (isManagerRole(loggedInEmployee) || reportsExportData.current.length - 1 !== index) {
                        return (<div>
                            <div style={{ fontWeight: "bold" }}>{(exportData.name === "" && reportsExportData.current.length - 1 === index) ? "Overall Total Calculate" : exportData.name}</div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "10px" }}>Hours:</span>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>{exportData.hours.toFixed(2)}</strong>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "10px" }}>Days:</span>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>{exportData.days}</strong>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "10px" }}>Orders:</span>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>{exportData.orders}</strong>
                                </div>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "10px" }}>Commissions:</span>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>${exportData.commissions.toFixed(2)}</strong>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "10px" }}>Credit Card Tips:</span>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>${exportData.creditTips.toFixed(2)}</strong>
                                </div>
                            </div>
                            <div style={{ borderBottom: "1px dashed", marginTop: "10px", marginBottom: "10px" }}></div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "10px" }}>Cash Sales:</span>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>${exportData.cash.toFixed(2)}</strong>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "10px" }}>Credit Card Sales:</span>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>${exportData.creditCard.toFixed(2)}</strong>
                                </div>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "10px" }}>Gift Card Sales:</span>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>${exportData.giftCard.toFixed(2)}</strong>
                                </div>
                            </div>
                            <div style={{ borderBottom: "1px dashed", marginTop: "10px", marginBottom: "10px" }}></div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ justifyContent: "flex-start" }}>
                                    <span style={{ marginRight: "10px" }}>Overall Sales:</span>
                                </div>
                                <div style={{ justifyContent: "flex-end" }}>
                                    <strong>${exportData.overallSale.toFixed(2)}</strong>
                                </div>
                            </div>
                            <div style={{ borderBottom: "1px solid", marginTop: "10px", marginBottom: "10px" }}></div>
                        </div>);
                    }
                })}
            </div>
        </div>
    );
};