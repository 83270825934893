import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AuthComponent from './components/Auth';
import MenuComponent from './components/Menu';
import SaleComponent from './components/Sale';
import OrdersComponent from './components/Orders';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { MenuItem } from './models/Menu.model';
import { RootState } from './reducers/root.reducer';
import EmployeesComponent from './components/Employees';
import SettingsComponent from './components/Settings';
import ReportsComponent from './components/Reports';
import { defaultPageInitialLoad } from './models/AppCommon.model';
import InfoComponent from './components/Info';

function App() {
  //redux states
  const authState = useSelector((state: RootState) => state.auth);
  const [activeMenuItem, setActiveMenuItem] = useState(MenuItem.employees);
  const [pageInitialLoad, setPageInitialLoad] = useState(defaultPageInitialLoad);
  const [isEditOrder, setIsEditOrder] = useState(false);

  const ProtectedRoute = ({ children }: any) => {
    if (!authState.isLoggedIn) {
      return <Navigate to="/login" />;
    }
    return children;
  };

  const EmployeeRoute = ({ children }: any) => {
    setActiveMenuItem(MenuItem.employees);
    return children;
  }

  return (
    <Router>
      <Routes>
        <Route index element={<Navigate to="/login" />} />
        <Route path="login" element={authState.isLoggedIn ? <Navigate to="/easy-manage/employees"   /> : <AuthComponent />} />
        <Route path="easy-manage" element={
          <ProtectedRoute>
            <MenuComponent activeMenuItem={activeMenuItem} setActiveMenuItem={setActiveMenuItem} pageInitialLoad={pageInitialLoad} setPageInitialLoad={setPageInitialLoad} setIsEditOrder={setIsEditOrder} />
          </ProtectedRoute>}>
          <Route path="sale" element={<SaleComponent isEditOrder={isEditOrder} setIsEditOrder={setIsEditOrder} pageInitialLoad={pageInitialLoad} setPageInitialLoad={setPageInitialLoad} />} />
          <Route path="orders" element={<OrdersComponent isEditOrder={isEditOrder} setIsEditOrder={setIsEditOrder} setActiveMenuItem={setActiveMenuItem} />} />
          <Route path="employees" element={
            <EmployeeRoute>
              <EmployeesComponent />
            </EmployeeRoute>
          } />
          <Route path="reports" element={<ReportsComponent />} />
          <Route path="settings" element={<SettingsComponent setPageInitialLoad={setPageInitialLoad}/>} />
          <Route path="*" element={<h1>Page Not Found</h1>} />
        </Route>
        <Route path="info" element={<InfoComponent />}/>
        <Route path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
    </Router>
  );
}

export default App;
