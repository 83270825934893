import { DECREASE_LOADER, INCREASE_LOADER } from "../actions/Loader.action";
import { Action } from "../models/AppCommon.model";

const loaderReducer = (state = 0, action: Action): any => {
    switch (action.type) {
        case INCREASE_LOADER:
            return state + 1;
        case DECREASE_LOADER:
            return state - 1;
        default:
            return state;
    }
};

export default loaderReducer