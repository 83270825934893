import { useState } from 'react';
import { Modal, Button, Grid, Message } from 'semantic-ui-react';
import { PinPadProps } from '../models/PinPad.model';
import '../styles/PinPad.css';

function PinPadModal({ pinPadModalState, setPinPadModalState, clearPinPadModal, onPinEntered }: PinPadProps) {
  const [pin, setPin] = useState<string>("");

  const handleButtonClick = (digit: number) => {
    setPinPadModalState(((prevState: any) => ({
      ...prevState,
      isInvalidPin: false
    })) as any);
    if (pin.length < 4) {
      setPin((prevPin) => {
        const newPin = prevPin + digit.toString();
        if (prevPin.length === 3) {
          handleEnter(newPin);
        }
        return newPin;
      });
    }
  };

  const handleDelete = () => {
    setPin((prevPin) => prevPin.slice(0, -1));
  };

  const handleClear = () => {
    setPin("");
  };

  const handleEnter = (pinEnter: string) => {
    if (pinEnter.length === 4) {
      onPinEntered(pinEnter);
      handleClear();
    }
  };

  const handleClose = () => {
    if (pinPadModalState.allowClose) {
      handleClear();
      clearPinPadModal();
    }
  };

  const renderButtons = () => {
    const buttons: JSX.Element[] = [];
    for (let i = 1; i <= 9; i++) {
      buttons.push(
        <Button className="pin-pad__button" key={i} onClick={() => handleButtonClick(i)}>
          {i}
        </Button>
      );
    }
    buttons.push(
      <Button className="pin-pad__button" key="clear" onClick={handleClear} icon="remove circle" />
    );
    buttons.push(
      <Button className="pin-pad__button" key={0} onClick={() => handleButtonClick(0)}>
        0
      </Button>
    );
    buttons.push(
      <Button className="pin-pad__button" key="delete" onClick={handleDelete} icon="long arrow alternate left" />
    );
    return buttons;
  };

  const showError = () => {
    return pinPadModalState.isInvalidPin;
  };

  return (
    <Modal
      closeIcon={pinPadModalState.allowClose}
      size="small"
      open={pinPadModalState.modalOpen}
      onClose={() => handleClose()}
    >
      <Modal.Content>
        {showError() && <Message negative className="pin-pad__error">
          <Message.Header>Invalid Pin</Message.Header>
        </Message>}
        <Grid centered>
          <Grid.Row className="pin-pad__grid-row">
            <Grid.Column width={5} textAlign="center">
              <div className="pin-pad__pin">{pin.replace(/./g, '*')}</div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="pin-pad__grid-row">{renderButtons().slice(0, 3)}</Grid.Row>
          <Grid.Row className="pin-pad__grid-row">{renderButtons().slice(3, 6)}</Grid.Row>
          <Grid.Row className="pin-pad__grid-row">{renderButtons().slice(6, 9)}</Grid.Row>
          <Grid.Row className="pin-pad__grid-row">{renderButtons().slice(9)}</Grid.Row>

          <Grid.Row className="pin-pad__grid-row">
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  );
}

export default PinPadModal;