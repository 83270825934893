import { Segment, Grid, Menu, Radio, Button, Icon, Form, Input, Label, Message } from 'semantic-ui-react';
import '../styles/Settings.css';
import '../styles/Common.css';
import { useEffect, useState } from 'react';
import { SettingMenuItem, SettingsProps } from '../models/Settings.model';
import { useDispatch, useSelector } from 'react-redux';
import { onLogout, updateUserRef } from '../actions/Auth.action';
import { clearLoggedInEmployee } from '../actions/LoggedInEmployee.action';
import { RootState } from '../reducers/root.reducer';
import { AuthState, errorMessages, initialAuthState, phoneNumberPattern } from '../models/Auth.model';
import { defaultPageInitialLoad } from '../models/AppCommon.model';
import { GET_EMPLOYEES } from '../actions/Employees.action';
import { fetchData } from '../actions/APIResponse.action';
import { GET_ORDERS } from '../actions/Orders.action';
import { GET_CATEGORIES } from '../actions/Sale.action';
import { firebaseFunctions } from '../firebase/firebaseService';
import { increaseLoader } from '../actions/Loader.action';

function SettingsComponent({ setPageInitialLoad }: SettingsProps) {
  const dispatch = useDispatch();

  const authState = useSelector((state: RootState) => state.auth);

  const [activeItem, setActiveItem] = useState(SettingMenuItem.profile);
  const [authLocalState, setAuthLocalState] = useState<AuthState>(initialAuthState);
  const [phoneValidate, setPhoneValidate] = useState({
    errorMessage: errorMessages.phoneErrorMessage,
    isNotValid: false
  });

  useEffect(() => {
    setAuthLocalState({
      ...authState
    });
  }, [authState]);

  const handleItemClick = (e: any, { name }: any) => {
    setActiveItem(name);
  }

  const handleSignOut = () => {
    dispatch(onLogout());
    dispatch(clearLoggedInEmployee());
    setPageInitialLoad(defaultPageInitialLoad);
    dispatch(fetchData(GET_EMPLOYEES, []));
    dispatch(fetchData(GET_ORDERS, []));
    dispatch(fetchData(GET_CATEGORIES, []));
  }



  const handleSave = () => {
    if (phoneNumberPattern.test(authLocalState.phone)) {
      dispatch(updateUserRef({ ...authLocalState }, true));
      setPhoneValidate(prevState => ({
        ...prevState,
        isNotValid: false
      }));
    } else {
      setPhoneValidate(prevState => ({
        ...prevState,
        isNotValid: true
      }));
    }
  }

  const handleModifyUserProfile = (e: any, { name, value }: any) => {
    setAuthLocalState((prevState: AuthState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleModifyTax = (e: any, { value }: any) => {
    setAuthLocalState((prevState: AuthState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        tax: value / 100
      }
    }));
  }

  const handleModifyPrintReceipt = () => {
    setAuthLocalState((prevState: AuthState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        printReceiptAfterCheckout: !prevState.settings.printReceiptAfterCheckout
      }
    }));
  }

  const handleModifyAutoLogoutTime = (e: any, { value }: any) => {
    setAuthLocalState((prevState: AuthState) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        autoLogoutTime: value * 1000
      }
    }));
  }

  const handleSubscriptionClick = async () => {
    dispatch(increaseLoader());
    const functionRef = firebaseFunctions.httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
      returnUrl: window.location.origin
    });
    window.location.assign(data.url);
  }

  return (
    <Grid>
      <Grid.Column width={6} className="container-height-scroll-bar">
        <Menu color="teal" fluid vertical pointing size="small">
          <Menu.Item
            name={SettingMenuItem.profile}
            active={activeItem === SettingMenuItem.profile}
            onClick={handleItemClick}
          />
          <Menu.Item
            name={SettingMenuItem.tax}
            active={activeItem === SettingMenuItem.tax}
            onClick={handleItemClick}
          />
          <Menu.Item
            name={SettingMenuItem.printReceipt}
            active={activeItem === SettingMenuItem.printReceipt}
            onClick={handleItemClick}
          >
            {SettingMenuItem.printReceipt}
          </Menu.Item >
          <Menu.Item
            name={SettingMenuItem.autoLogoutTime}
            active={activeItem === SettingMenuItem.autoLogoutTime}
            onClick={handleItemClick}
          />
          <Menu.Item
            className="settings-page__subscription"
            name={SettingMenuItem.subscription}
            onClick={() => handleSubscriptionClick()}
          />
          <Menu.Item
            className="settings-page__sign-out"
            name={SettingMenuItem.signOut}
            onClick={() => handleSignOut()}
          />
        </Menu>
      </Grid.Column>
      <Grid.Column stretched width={10}>
        <Segment secondary>
          <Segment className="container-height-scroll-bar settings-page__top-segment">
            {phoneValidate.isNotValid && <Message negative>
              <Message.Header>{phoneValidate.errorMessage}</Message.Header>
            </Message>}
            {activeItem === SettingMenuItem.profile && <Form>
              <Form.Field>
                <label>Business Name</label>
                <Input
                  name="businessName"
                  type="text"
                  value={authLocalState.businessName}
                  onChange={handleModifyUserProfile}
                />
              </Form.Field>
              <Form.Field>
                <label>Phone Number</label>
                <Input
                  name="phone"
                  type="text"
                  value={authLocalState.phone}
                  onChange={handleModifyUserProfile}
                />
              </Form.Field>
            </Form>}

            {activeItem === SettingMenuItem.tax && <Form>
              <Form.Field>
                <label>Tax</label>
                <Input
                  type="number"
                  value={authLocalState.settings.tax * 100}
                  onChange={handleModifyTax}
                  labelPosition="right"
                >
                  <input />
                  <Label>%</Label>
                </Input>
              </Form.Field>
            </Form>}

            {activeItem === SettingMenuItem.printReceipt && <Form>
              <Form.Field>
                <label>Print Receipt After Checkout</label>
                <Radio
                  toggle
                  checked={authLocalState.settings.printReceiptAfterCheckout}
                  onChange={handleModifyPrintReceipt}
                />
              </Form.Field>
            </Form>}

            {activeItem === SettingMenuItem.autoLogoutTime && <Form>
              <Form.Field>
                <label>Auto Logout Time</label>
                <Input
                  type="number"
                  value={authLocalState.settings.autoLogoutTime / 1000}
                  onChange={handleModifyAutoLogoutTime}
                  labelPosition="right"
                >
                  <input />
                  <Label>Second</Label>
                </Input>
              </Form.Field>
            </Form>}
          </Segment>
          <Segment className="settings-page__bottom-segment">
            <div className="ui two buttons">
              <Button className="settings-page__save-button" color="teal" onClick={() => handleSave()}>
                <Icon name="save" /> Save
              </Button>
            </div>
          </Segment>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

export default SettingsComponent;