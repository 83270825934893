import { PageInitialLoad } from "./AppCommon.model";

export interface MenuProps {
    activeMenuItem: MenuItem;
    setActiveMenuItem: (value: MenuItem) => void;
    pageInitialLoad: PageInitialLoad;
    setPageInitialLoad: (value: PageInitialLoad) => void;
    setIsEditOrder: (value: boolean) => void;
}

export enum MenuItem {
    sale = "sale",
    orders = "orders",
    settings = "settings",
    reports = "reports",
    employees = "employees"
}