import { OrderDetail, OrderStatus } from "../models/OrderDetail.model";
import { FilterState, FirebaseCommonFiledNames, FirebaseDatabaseNames, internetCheck } from "../models/AppCommon.model";
import { Dispatch } from "react";
import { clearMessage, fetchData, updateErrorMessage } from "./APIResponse.action";
import { db } from "../firebase/firebaseService";
import { AuthState } from "../models/Auth.model";
import { decreaseLoader, increaseLoader } from "./Loader.action";
import { Employee } from "../models/Employees.model";

// Action types
export const GET_ORDERS = "GET_ORDERS";
export const ADD_ORDER = "ADD_ORDER";
export const EDIT_ORDER = "EDIT_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";

// Action creator
export function getOrders(authState: AuthState, filters: FilterState, employees: Employee[], isOrdersPage: boolean): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        let query = db.collection(FirebaseDatabaseNames.orders).where(FirebaseCommonFiledNames.userId, "==", authState.id);
        if (filters.startDate) {
            query = query.where(FirebaseCommonFiledNames.createTimestamp, ">=", filters.startDate);
        }
        if (filters.endDate) {
            query = query.where(FirebaseCommonFiledNames.createTimestamp, "<=", filters.endDate);
        }
        if (filters.selectOrderStatus && filters.selectOrderStatus !== OrderStatus.none && isOrdersPage) {
            query = query.where(FirebaseCommonFiledNames.status, "==", filters.selectOrderStatus);
        } else if (!isOrdersPage) {
            query = query.where(FirebaseCommonFiledNames.status, "==", OrderStatus.close);
        }
        query.get()
            .then(querySnapshot => {
                const orders: any[] = [];
                querySnapshot.forEach(doc => {
                    orders.push({ id: doc.id, ...doc.data(), createTimestamp: doc.data().createTimestamp.toDate() });
                });
                dispatch(fetchData(GET_ORDERS, [...orders.sort((o1: OrderDetail, o2: OrderDetail) => {
                    const orderOneEmployeeName: string = o1.employeeId ? employees.find((employee) => employee.id === o1.employeeId)!.name : o1.employee!.name;
                    const orderTwoEmployeeName: string = o2.employeeId ? employees.find((employee) => employee.id === o2.employeeId)!.name : o2.employee!.name;
                    return orderOneEmployeeName.toLowerCase().localeCompare(orderTwoEmployeeName.toLowerCase());
                })]));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error getting orders: " + error.message));
                dispatch(fetchData(GET_ORDERS, []));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function addOrder(order: OrderDetail): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.orders).add(order)
            .then(docRef => {
                dispatch(fetchData(ADD_ORDER, { id: docRef.id, ...order }));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error adding order: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function editOrder(order: OrderDetail): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.orders).doc(order.id).update(order)
            .then(() => {
                dispatch(fetchData(EDIT_ORDER, order));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error editing order: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function deleteOrder(order: OrderDetail): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.orders).doc(order.id).delete()
            .then(() => {
                dispatch(fetchData(DELETE_ORDER, order));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error deleting order: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}