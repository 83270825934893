import { Modal, Button, Icon, Form, Message, Segment, Grid } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';
import { ClockData, Employee, EmployeeModalProps, EmployeeModalType, getAllClockDataExceptSelectedDate, groupByClockData, roleOptions } from '../models/Employees.model';
import { clockTimeFormat, dateFormat, filterEmployeeByActive, getEndDay, getStartDay, getTwoDClockTimesArray, isAdmin, modalTitles } from '../models/AppCommon.model';
import { addEmployee, editEmployee } from '../actions/Employees.action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reducers/root.reducer';
import moment from "moment";
import '../styles/Employees.css';
import '../styles/Common.css';

function EmployeeModal({ employeeModalState, employees, currentSelectedEmployee, setCurrentSelectedEmployee, clearEmployeeModal }: EmployeeModalProps) {
  const dispatch = useDispatch();
  const authState = useSelector((state: RootState) => state.auth);

  const [error, setError] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showModifyClockContainer, setShowModifyClockContainer] = useState(false);
  const [clockTimeList, setClockTimeList] = useState<ClockData[][]>([]);
  const [allClockTimeListExceptSelectedDate, setAllClockTimeListExceptSelectedDate] = useState<ClockData[]>([]);

  useEffect(() => {
    if (showModifyClockContainer) {
      calculateClockTime(selectedDate);
    }
  }, [showModifyClockContainer]);

  const handleEditEmployeeSubmit = () => {
    // Check if name already exists
    const nameExists = filterEmployeeByActive(employees).some(
      (employee: Employee) =>
        employee.id !== currentSelectedEmployee.id && employee.name.toLowerCase() === currentSelectedEmployee.name.toLowerCase()
    );

    const pinExists = filterEmployeeByActive(employees).some(
      (employee: Employee) =>
        employee.id !== currentSelectedEmployee.id && employee.pin === currentSelectedEmployee.pin
    );

    // Check if PIN is less than 4 digits
    const isPinInvalid = currentSelectedEmployee.pin.length !== 4;

    // Check if name or role is empty
    const isNameEmpty = currentSelectedEmployee.name.trim() === "";
    const isRoleEmpty = currentSelectedEmployee.role.trim() === "";

    if (nameExists || isPinInvalid || isNameEmpty || isRoleEmpty || pinExists) {
      let errorMessage = "";
      if (nameExists) {
        errorMessage = "The name is already taken.";
      } else if (isNameEmpty) {
        errorMessage = "Please enter a name.";
      } else if (pinExists) {
        errorMessage = "The PIN is already taken.";
      } else if (isPinInvalid) {
        errorMessage = "The PIN must be 4 digits.";
      } else if (isRoleEmpty) {
        errorMessage = "Please select a role.";
      }
      setError(errorMessage);
      return;
    }

    if (employeeModalState.modalType === EmployeeModalType.editEmployee) {
      if (showModifyClockContainer) {
        let isClockTimeValid = true;
        const convertedClockTimeList: ClockData[] = [];
        clockTimeList.forEach((value: ClockData[]) => {
          if (!value[0].clockDateTime || !value[1].clockDateTime) {
            isClockTimeValid = false;
            setError("Please enter all the clock in and clock out time.");
            return;
          }
        });

        if (isClockTimeValid) {
          clockTimeList.forEach((clickTime: ClockData[]) => {
            const formatClockInDate = {
              ...clickTime[0],
              clockDateTime: new Date(clickTime[0].clockDateTime)
            }
            const formatClockOutDate = {
              ...clickTime[1],
              clockDateTime: new Date(clickTime[1].clockDateTime)
            }
            convertedClockTimeList.push(formatClockInDate, formatClockOutDate);
          });
        } else {
          return;
        }
        dispatch(editEmployee({ ...currentSelectedEmployee, clockData: [...allClockTimeListExceptSelectedDate, ...convertedClockTimeList] }));
      } else {
        dispatch(editEmployee(currentSelectedEmployee));
      }

    } else if (employeeModalState.modalType === EmployeeModalType.addEmployee) {
      currentSelectedEmployee.userId = authState.id ? authState.id : "";
      dispatch(addEmployee(currentSelectedEmployee));
    }

    closeModal();
    setError("");
  };

  const handleEditEmployeeChange = (e: any, { name, value }: any) => {
    setCurrentSelectedEmployee((prevState: any) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleEditEmployeeCancel = () => {
    closeModal();
  };

  const handleModifyClockClick = () => {
    setShowModifyClockContainer(!showModifyClockContainer);
    setError("");
  };

  const handleDateChange = (date: Date) => {
    setSelectedDate(date);
    setClockTimeList([]);
    setAllClockTimeListExceptSelectedDate([]);
    calculateClockTime(date);
    setError("");
  };

  const handleClockDateTimeChange = (e: any, selectedIndex: number, isClockIn: boolean) => {
    let isValid = true;
    let errorMessage = "";
    const formatSelectedDate = moment(selectedDate).format(dateFormat);
    if (moment(e.target.value).format(dateFormat) !== formatSelectedDate) {
      isValid = false;
      setError(`The 'Clock In/Out Time' must under '${formatSelectedDate}'.`);
      return;
    }
    clockTimeList.forEach((value, index) => {
      if (index === selectedIndex) {
        const clockInTime = isClockIn ? new Date(e.target.value).getTime() : new Date(value[0].clockDateTime).getTime();
        const clockOutTime = isClockIn ? new Date(value[1].clockDateTime).getTime() : new Date(e.target.value).getTime();
        if (clockInTime >= clockOutTime) {
          isValid = false;
          errorMessage = "The 'Clock Out Time' can not be set to an earlier time than the 'Clock In Time'.";
          return;
        } else if (clockInTime && clockOutTime) {
          clockTimeList.forEach((innerValue, innerIndex) => {
            if (innerIndex !== selectedIndex) {
              const innerClockInTime = new Date(innerValue[0].clockDateTime).getTime();
              const innerClockOutTime = new Date(innerValue[1].clockDateTime).getTime();
              if (clockInTime <= innerClockInTime && clockOutTime >= innerClockOutTime) {
                isValid = false;
                errorMessage = "Repeated 'Clock In/Out Time' slot found.";
                return;
              }
            }
          });
        }
      } else {
        const clockInTime = new Date(value[0].clockDateTime).getTime();
        const clockOutTime = new Date(value[1].clockDateTime).getTime();
        const inputClockTime = new Date(e.target.value).getTime();
        if (clockInTime <= inputClockTime && clockOutTime >= inputClockTime) {
          isValid = false;
          errorMessage = "The 'Clock In/Out Time' can not be set in the middle of another clock time slots.";
          return;
        }
      }
    });

    if (isValid) {
      setClockTimeList(prevState => ([
        ...prevState.map((value, index) => {
          if (index === selectedIndex) {
            return isClockIn ? [{ ...value[0], clockDateTime: e.target.value }, value[1]] : [value[0], { ...value[1], clockDateTime: e.target.value }];
          }
          return value;
        })
      ]));
      setError("");
    } else {
      setError(errorMessage);
    }
  };

  const handleAddClockTime = () => {
    setClockTimeList(prevState => ([
      ...prevState,
      [{ isClockIn: true, clockDateTime: "" }, { isClockIn: false, clockDateTime: "" }]
    ]));
    setError("");
  };

  const handleDeleteClockTime = (selectedIndex: number) => {
    setClockTimeList(prevState => ([
      ...prevState.filter((value, index) => index !== selectedIndex)
    ]));
    setError("");
  };

  const calculateClockTime = (date: Date) => {
    const startDate = getStartDay(date);
    const endDate = getEndDay(date);

    Object.values(groupByClockData(
      currentSelectedEmployee,
      {
        startDate: startDate,
        endDate: endDate,
        selectRange: null,
        selectOrderStatus: null
      })).forEach((value: any) => {
        const twoDClockTimesArray = getTwoDClockTimesArray(value).map((clickTime: ClockData[]) => {
          const formatClockInDate = {
            ...clickTime[0],
            clockDateTime: clickTime[0].clockDateTime ? moment(clickTime[0].clockDateTime).format(clockTimeFormat) : ""
          }
          const formatClockOutDate = {
            ...clickTime[1],
            clockDateTime: clickTime[1].clockDateTime ? moment(clickTime[1].clockDateTime).format(clockTimeFormat) : ""
          }
          return [formatClockInDate, formatClockOutDate]
        });
        setClockTimeList(twoDClockTimesArray);
      });

    setAllClockTimeListExceptSelectedDate(getAllClockDataExceptSelectedDate(currentSelectedEmployee,
      {
        startDate: startDate,
        endDate: endDate,
        selectRange: null,
        selectOrderStatus: null
      }));
  };

  const closeModal = () => {
    setError("");
    setSelectedDate(new Date());
    setShowModifyClockContainer(false);
    setClockTimeList([]);
    setAllClockTimeListExceptSelectedDate([]);
    clearEmployeeModal();
  };

  return (
    <Modal
      size="small"
      open={employeeModalState.modalOpen}
    >
      <Modal.Header>{modalTitles[employeeModalState.modalType]}</Modal.Header>
      <Modal.Content className="employee-modal__container">
        {error && (
          <Message className="element-sticky" negative>
            <p>{error}</p>
          </Message>
        )}
        <Form>
          <Form.Input
            label="Name"
            name="name"
            value={currentSelectedEmployee.name}
            onChange={handleEditEmployeeChange}
          />
          <Form.Input
            label="PIN"
            name="pin"
            value={currentSelectedEmployee.pin}
            onChange={handleEditEmployeeChange}
            type="password"
          />
          {!isAdmin(currentSelectedEmployee) && <Form.Select
            label="Role"
            name="role"
            options={roleOptions}
            value={currentSelectedEmployee.role}
            onChange={handleEditEmployeeChange}
          />}
        </Form>
        <br />
        {(employeeModalState.modalType === EmployeeModalType.editEmployee && !isAdmin(currentSelectedEmployee)) && <div>
          <Button color="orange" onClick={() => handleModifyClockClick()}>
            <Icon name="time" />
            Modify Clock In/Out
          </Button>
          {showModifyClockContainer && <Segment>
            <Grid>
              <Grid.Column width={8}>
                <Form>
                  <Form.Group inline>
                    <Form.Field>
                      <DatePicker
                        selected={selectedDate}
                        onChange={handleDateChange}
                        dateFormat="MM/dd/yyyy"
                        placeholderText="Date"
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Grid.Column>
              <Grid.Column width={8}>
                <Button
                  floated="right"
                  icon
                  labelPosition="left"
                  color="teal"
                  onClick={() => handleAddClockTime()}
                >
                  <Icon name="add" />
                  Add Clock In/Out Time
                </Button>
              </Grid.Column>
            </Grid>
            {
              clockTimeList.map((clickTime: ClockData[], index: number) => {
                return <Segment key={index}>
                  <div className="employee-modal__clock-delete-button">
                    <Button circular size="mini" icon="close" onClick={() => handleDeleteClockTime(index)} />
                  </div>
                  <Form>
                    <Form.Field>
                      <label>Clock In</label>
                      <Form.Input
                        type="datetime-local"
                        value={clickTime[0].clockDateTime}
                        onChange={() => handleClockDateTimeChange(event, index, true)}
                        error={!clickTime[0].clockDateTime}
                      >
                      </Form.Input>
                    </Form.Field>
                    <Form.Field>
                      <label>Clock Out</label>
                      <Form.Input
                        type="datetime-local"
                        value={clickTime[1].clockDateTime}
                        onChange={() => handleClockDateTimeChange(event, index, false)}
                        error={!clickTime[1].clockDateTime}
                      >
                      </Form.Input>
                    </Form.Field>
                  </Form>
                </Segment>
              })
            }
          </Segment>}
        </div>}
      </Modal.Content>
      <Modal.Actions>
        <Button color="teal" onClick={() => handleEditEmployeeSubmit()}>
          <Icon name="save" />
          Submit
        </Button>
        <Button onClick={() => handleEditEmployeeCancel()}>
          <Icon name="cancel" />
          Cancel
        </Button>
      </Modal.Actions>
    </Modal >
  );
}

export default EmployeeModal;
