import { Action, defaultResponceSetup } from "../models/AppCommon.model";

// Action types
export const UPDATE_MESSAGE = "UPDATE_MESSAGE";

export const fetchData = (type: string, payload: any) => ({
    type: type,
    payload: payload
});

// Action creator
export function updateSuccessMessage(message: string): Action {
    return {
        type: UPDATE_MESSAGE,
        payload: {
            showMessages: true,
            isErrorMessage: false,
            message: message
        }
    }
}

export function updateErrorMessage(message: string): Action {
    return {
        type: UPDATE_MESSAGE,
        payload: {
            showMessages: true,
            isErrorMessage: true,
            message: message
        }
    }
}

export function clearMessage(): Action {
    return {
        type: UPDATE_MESSAGE,
        payload: defaultResponceSetup
    }
}