import React from 'react';
import { connect } from 'react-redux';
import { getCategories, addCategory, editCategory, deleteCategory } from '../actions/Sale.action';
import { Menu, Grid, Icon, Segment } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import '../styles/Sale.css';
import '../styles/Common.css';
import { RootState } from '../reducers/root.reducer';
import { isAdmin, isEmptyArray } from '../models/AppCommon.model';
import { Category, CategoryItem, patternEnum, SaleActionCreators, SaleEnum, SaleLocalState, SaleState } from '../models/Sale.model';
import CategoryModal from '../modals/Category.modal';
import CategoryItemModal from '../modals/CategoryItem.modal';
import OrderDetailComponent from './OrderDetail';
import { OrderDetailItem } from '../models/OrderDetail.model';
import { updateOrderDetail } from '../actions/OrderDetail.action';

class SaleComponent extends React.Component<SaleState & SaleActionCreators, SaleLocalState> {

    constructor(props: SaleState & SaleActionCreators) {
        super(props);
        this.state = {
            category: null,
            categoryModalConfig: {
                modalOpen: false,
                modalType: ""
            },
            categoryItemModalConfig: {
                modalOpen: false,
                modalType: ""
            }
        };

        this.handleModifyCategory = this.handleModifyCategory.bind(this);
        this.handleModifyCategoryItemName = this.handleModifyCategoryItemName.bind(this);
        this.handleModifyCategoryItemReceiptName = this.handleModifyCategoryItemReceiptName.bind(this);
        this.handleModifyCategoryItemPrice = this.handleModifyCategoryItemPrice.bind(this);
        this.handleModifyCategoryItemCommission = this.handleModifyCategoryItemCommission.bind(this);
        this.clearCategoryModal = this.clearCategoryModal.bind(this);
        this.clearCategoryItemModal = this.clearCategoryItemModal.bind(this);
    }

    static getDerivedStateFromProps(props: SaleState & SaleActionCreators, currentLocalState: SaleLocalState) {
        const selectedCategory: Category | undefined = props.categories.find((category: Category) => category.id === currentLocalState.category?.id);
        if (!currentLocalState.category && !isEmptyArray(props.categories)) {
            return {
                category: props.categories[0]
            };
        } else if (selectedCategory && JSON.stringify(selectedCategory) !== JSON.stringify(currentLocalState.category)) {
            return {
                category: selectedCategory
            };
        }
        return null;
    }

    componentDidMount(): void {
        if (!this.props.pageInitialLoad!.sale) {
            this.props.getCategories(this.props.authState);
            this.props.setPageInitialLoad!({
                ...this.props.pageInitialLoad!,
                sale: true
            });
        }
    }

    hasEditAccess(): boolean {
        return isAdmin(this.props.loggedInEmployee);
    }

    isAmountCommission(categoryItem: CategoryItem): boolean {
        return categoryItem && (categoryItem.commissionAmount !== null && categoryItem.commissionPercentage === null);
    }

    hasNoCommissionAssign(categoryItem: CategoryItem): boolean {
        return categoryItem && (categoryItem.commissionAmount === null && categoryItem.commissionPercentage === null);
    }

    getCommissionValue(categoryItem: CategoryItem): number {
        return this.isAmountCommission(categoryItem) ? categoryItem.commissionAmount! : (categoryItem && categoryItem.commissionPercentage !== null ? categoryItem.commissionPercentage! : 0);
    }

    handleCategoryClick(category: Category): void {
        this.setState({
            category: category
        });
    }

    handleAddCategaory(): void {
        this.setState({
            category: {
                id: SaleEnum.addCategory,
                userId: this.props.authState.id ? this.props.authState.id : "",
                name: SaleEnum.addCategory,
                items: []
            },
            categoryModalConfig: {
                modalOpen: true,
                modalType: SaleEnum.addCategory,
                modalValue: {
                    name: "",
                    userId: this.props.authState.id ? this.props.authState.id : "",
                    items: []
                }
            }
        });
    }

    handleCategoryEdit(category: Category): void {
        this.setState({
            category: category,
            categoryModalConfig: {
                modalOpen: true,
                modalType: SaleEnum.editCategory,
                modalValue: {
                    id: category.id,
                    userId: category.userId,
                    name: category.name,
                    items: category.items
                }
            }
        });
    }

    handleCategoryItemClick(categoryItem: CategoryItem): void {
        if (this.hasEditAccess()) {
            this.configCategoryItemModal(categoryItem, SaleEnum.editCategoryItem);
        } else {
            if (categoryItem.isVariablePrice) {
                this.configCategoryItemModal(categoryItem, SaleEnum.modifyVariablePrice);
            } else {
                const orderItem: OrderDetailItem | undefined = this.props.orderDetail.items.find((item: OrderDetailItem) => item.id === categoryItem.id);
                if (orderItem) {
                    orderItem.quantity = orderItem.quantity + 1;
                }
                this.props.updateOrderDetail({
                    ...this.props.orderDetail,
                    items: orderItem ? [...this.props.orderDetail.items] : [...this.props.orderDetail.items, { ...categoryItem, quantity: 1 }]
                });
            }
        }
    }

    handleAddCategoryItem(): void {
        this.setState({
            categoryItemModalConfig: {
                modalOpen: true,
                modalType: SaleEnum.addCategoryItem,
                modalValue: {
                    itemName: "",
                    itemReceiptName: "",
                    itemPrice: 0,
                    commission: 0,
                    isAmountCommission: true,
                    commissionPercentage: null,
                    commissionAmount: null
                },
                currentCategory: this.state.category
            }
        });
    }

    handleModifyCategory = (e: any) => {
        this.setState({
            categoryModalConfig: { ...this.state.categoryModalConfig, modalValue: { ...this.state.categoryModalConfig.modalValue!, name: e.target.value } }
        });
    }

    handleModifyCategoryItemName = (e: any) => {
        this.setState({
            categoryItemModalConfig: { ...this.state.categoryItemModalConfig, modalValue: { ...this.state.categoryItemModalConfig.modalValue!, itemName: e.target.value } }
        });
    }

    handleModifyCategoryItemReceiptName = (e: any) => {
        this.setState({
            categoryItemModalConfig: { ...this.state.categoryItemModalConfig, modalValue: { ...this.state.categoryItemModalConfig.modalValue!, itemReceiptName: e.target.value } }
        });
    }

    handleModifyCategoryItemPrice = (e: any) => {
        this.setState({
            categoryItemModalConfig: { ...this.state.categoryItemModalConfig, modalValue: { ...this.state.categoryItemModalConfig.modalValue!, itemPrice: e.target.value } }
        });
    }

    handleModifyCategoryItemCommission = (e: any) => {
        this.setState({
            categoryItemModalConfig: { ...this.state.categoryItemModalConfig, modalValue: { ...this.state.categoryItemModalConfig.modalValue!, commission: e.target.value } }
        });
    }

    handleModifyCommissionUnit = (e: any, data: any) => {
        this.setState({
            categoryItemModalConfig: { ...this.state.categoryItemModalConfig, modalValue: { ...this.state.categoryItemModalConfig.modalValue!, isAmountCommission: data.value === patternEnum.amount } }
        });
    }

    handleModifyIsVariablePrice = (e: any, data: any) => {
        this.setState({
            categoryItemModalConfig: { ...this.state.categoryItemModalConfig, modalValue: { ...this.state.categoryItemModalConfig.modalValue!, isVariablePrice: data.checked, itemPrice: 0 } }
        });
    }

    clearCategoryModal(): void {
        this.setState({
            categoryModalConfig: {
                modalOpen: false,
                modalType: "",
                modalValue: undefined
            }
        });
    }

    clearCategoryItemModal(): void {
        this.setState({
            categoryItemModalConfig: {
                modalOpen: false,
                modalType: "",
                modalValue: undefined
            }
        });
    }

    configCategoryItemModal(categoryItem: CategoryItem, modalType: SaleEnum): void {
        this.setState({
            categoryItemModalConfig: {
                modalOpen: true,
                modalType: modalType,
                modalValue: {
                    ...categoryItem,
                    commission: this.getCommissionValue(categoryItem),
                    isAmountCommission: this.isAmountCommission(categoryItem),
                },
                currentCategory: this.state.category
            }
        });
    }

    render() {
        return (
            <div>
                <Grid>
                    <Grid.Column width={3}>
                        <Menu color="teal" fluid vertical size="small" className="container-height-scroll-bar">
                            {
                                this.props.categories && this.props.categories.map((category: Category) => {
                                    return <Menu.Item
                                        className="sale-page__wrap-text"
                                        key={category.id}
                                        name={category.name}
                                        active={this.state.category?.name === category.name}
                                        onClick={() => this.handleCategoryClick(category)}
                                    >
                                        {category.name}
                                        {this.hasEditAccess() && <Icon name="edit" onClick={() => this.handleCategoryEdit(category)} />}
                                    </Menu.Item >
                                })
                            }
                            {this.hasEditAccess() && <Menu.Item
                                className="sale-page__wrap-text"
                                key={SaleEnum.addCategory}
                                name={SaleEnum.addCategory}
                                active={this.state.category?.name === SaleEnum.addCategory}
                                onClick={() => this.handleAddCategaory()}
                            >
                                Add Category Menu
                                <Icon name="add" />
                            </Menu.Item>}
                        </Menu>
                    </Grid.Column>

                    <Grid.Column stretched width={7}>
                        <Segment secondary className="container-height-scroll-bar">
                            {
                                this.state.category?.items && this.state.category?.items.map((categoryItem: CategoryItem) => {
                                    return <Segment
                                        key={categoryItem.id}
                                        size="large"
                                        className="sale-page__category-item"
                                        onClick={() => this.handleCategoryItemClick(categoryItem)}
                                    >
                                        <div className="sale-page__wrap-text"><b>{categoryItem.itemName}</b>{this.hasEditAccess() && <Icon name="edit" />}</div>
                                        <div className="sale-page__font-size-15"><b>Price: $</b><span>{categoryItem.itemPrice}</span></div>
                                        <div className="sale-page__font-size-10"><b>Commission: </b><span>{this.isAmountCommission(categoryItem) ? `$${this.getCommissionValue(categoryItem)}` : `${this.getCommissionValue(categoryItem)}%`}</span></div>
                                    </Segment>
                                })
                            }
                            {this.hasEditAccess() && this.state.category?.name !== SaleEnum.addCategory && this.state.category?.id && <Segment
                                key={SaleEnum.addCategoryItem}
                                size="large"
                                textAlign='center'
                                className="sale-page__category-item"
                                onClick={() => this.handleAddCategoryItem()}
                            >
                                <br />
                                <Icon name="add" />
                            </Segment>}
                        </Segment>
                    </Grid.Column>

                    <Grid.Column stretched width={6}>
                        <Segment secondary className="container-height-scroll-bar">
                            {this.hasEditAccess() && <div>Admin Mode Enabled</div>}
                            {!this.hasEditAccess() &&
                                <OrderDetailComponent
                                    orderDetailState={this.props.orderDetail}
                                    isSalePage={true}
                                    isEditOrder={this.props.isEditOrder!}
                                    setIsEditOrder={this.props.setIsEditOrder!}
                                />}
                        </Segment>
                    </Grid.Column>
                </Grid>

                <CategoryModal
                    categoryState={this.state.categoryModalConfig}
                    addCategory={this.props.addCategory}
                    editCategory={this.props.editCategory}
                    deleteCategory={this.props.deleteCategory}
                    handleModifyCategory={this.handleModifyCategory}
                    clearCategoryModal={this.clearCategoryModal}
                />

                <CategoryItemModal
                    categoryItemState={this.state.categoryItemModalConfig}
                    orderDetail={this.props.orderDetail}
                    isAmountCommission={this.isAmountCommission}
                    hasNoCommissionAssign={this.hasNoCommissionAssign}
                    handleModifyCategoryItemName={this.handleModifyCategoryItemName}
                    handleModifyCategoryItemReceiptName={this.handleModifyCategoryItemReceiptName}
                    handleModifyCategoryItemPrice={this.handleModifyCategoryItemPrice}
                    handleModifyCategoryItemCommission={this.handleModifyCategoryItemCommission}
                    handleModifyCommissionUnit={this.handleModifyCommissionUnit}
                    handleModifyIsVariablePrice={this.handleModifyIsVariablePrice}
                    updateOrderDetail={this.props.updateOrderDetail}
                    clearCategoryItemModal={this.clearCategoryItemModal}
                />
            </div>
        );
    }
}

function mapStateToProps({ auth, categories, orderDetail, loggedInEmployee }: RootState): SaleState {
    return { authState: { ...auth }, categories: [...categories], orderDetail: { ...orderDetail }, loggedInEmployee: { ...loggedInEmployee } };
}

const mapDispatchToProps: SaleActionCreators = {
    getCategories: getCategories,
    addCategory: addCategory,
    editCategory: editCategory,
    deleteCategory: deleteCategory,
    updateOrderDetail: updateOrderDetail
}

export default connect<SaleState, SaleActionCreators, any, any>(mapStateToProps, mapDispatchToProps)(SaleComponent)
