import { Category, CategoryItem, emptyCategoryItems } from "../models/Sale.model";
import { FirebaseCommonFiledNames, FirebaseDatabaseNames, internetCheck, sortByPropertyName } from "../models/AppCommon.model";
import { Dispatch } from "react";
import { clearMessage, fetchData, updateErrorMessage } from "./APIResponse.action";
import { db } from "../firebase/firebaseService";
import { AuthState } from "../models/Auth.model";
import { decreaseLoader, increaseLoader } from "./Loader.action";

// Action types
export const GET_CATEGORIES = "GET_CATEGORIES";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const EDIT_CATEGORY = "EDIT_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";

// Action creator
export function getCategories(authState: AuthState): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.categories).where(FirebaseCommonFiledNames.userId, "==", authState.id).get()
            .then(querySnapshot => {
                const categories: any[] = [];
                const categoriesWithItems: any[] = [];
                querySnapshot.forEach(doc => {
                    categories.push({ id: doc.id, ...doc.data() });
                });
                sortByPropertyName(categories, "name").forEach(category => {
                    const items: any[] = [];
                    db.collection(FirebaseDatabaseNames.categories).doc(category.id).collection(FirebaseDatabaseNames.items).get()
                        .then((snapshot: any) => {
                            snapshot.forEach((itemDoc: any) => {
                                items.push({ id: itemDoc.id, ...itemDoc.data() });
                            });
                            categoriesWithItems.push({ ...category, items: [...sortByPropertyName(items, "itemName")] });
                            dispatch(fetchData(GET_CATEGORIES, [...categoriesWithItems]));
                        })
                        .catch((error: any) => {
                            dispatch(updateErrorMessage("Error getting category items: " + error.message));
                        });
                });
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error getting categories: " + error.message));
                dispatch(fetchData(GET_CATEGORIES, []));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function addCategory(category: Category): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.categories).add(category)
            .then(docRef => {
                dispatch(fetchData(ADD_CATEGORY, { id: docRef.id, ...category }));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error adding category: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function editCategory(category: Category): any {
    const cloneCategory = { ...category };
    emptyCategoryItems(cloneCategory);
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.categories).doc(category.id).update(cloneCategory)
            .then(() => {
                dispatch(fetchData(EDIT_CATEGORY, category));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error editing category: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function deleteCategory(category: Category): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.categories).doc(category.id).delete()
            .then(() => {
                dispatch(fetchData(DELETE_CATEGORY, category));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error deleting category: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function addCategoryItem(category: Category, categoryItem: CategoryItem): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.categories).doc(category.id).collection(FirebaseDatabaseNames.items).add(categoryItem)
            .then(docRef => {
                category.items.push({ id: docRef.id, ...categoryItem });
                category.items = sortByPropertyName(category.items, "itemName");
                dispatch(fetchData(EDIT_CATEGORY, { ...category }));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error adding category item: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function editCategoryItem(category: Category, categoryItem: CategoryItem): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.categories).doc(category.id).collection(FirebaseDatabaseNames.items).doc(categoryItem.id).update(categoryItem)
            .then(() => {
                const categoryItems: CategoryItem[] = category.items.map(item => item.id === categoryItem.id ? categoryItem : item);
                category.items = sortByPropertyName(categoryItems, "itemName");
                dispatch(fetchData(EDIT_CATEGORY, category));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error editing category item: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function deleteCategoryItem(category: Category, categoryItem: CategoryItem): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.categories).doc(category.id).collection(FirebaseDatabaseNames.items).doc(categoryItem.id).delete()
            .then(() => {
                const categoryItems: CategoryItem[] = category.items.filter(item => item.id !== categoryItem.id);
                category.items = categoryItems;
                dispatch(fetchData(EDIT_CATEGORY, category));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error deleting category item: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}