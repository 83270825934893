import { ADD_ORDER, DELETE_ORDER, EDIT_ORDER, GET_ORDERS } from "../actions/Orders.action";
import { OrderDetail } from "../models/OrderDetail.model";
import { Action } from "../models/AppCommon.model";

const orderReducer = (state: OrderDetail[] = [], action: Action): OrderDetail[] => {
    switch (action.type) {
        case GET_ORDERS:
            return [...action.payload];
        case ADD_ORDER:
            return [...state, action.payload];
        case EDIT_ORDER:
            return [...state.map((order: OrderDetail) => order.id === action.payload.id ? action.payload : order)];
        case DELETE_ORDER:
            return [...state.filter((order: OrderDetail) => order.id !== action.payload.id)];
        default:
            return state;
    }
};

export default orderReducer