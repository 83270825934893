import { Modal, Button, Icon } from 'semantic-ui-react';
import { ConfirmationModalProps } from '../models/Sale.model';

function ConfirmationModal({ confirmationState, handleNoClick, handleYesClick }: ConfirmationModalProps) {
  return (
    <Modal
      size="small"
      open={confirmationState.modalOpen}
    >
      <Modal.Header>{confirmationState.modalType}</Modal.Header>
      <Modal.Content>
        <div>
          {confirmationState.modalContentMessage}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={handleNoClick}>
          <Icon name="remove" /> No
        </Button>
        <Button color="green" onClick={handleYesClick}>
          <Icon name="checkmark" /> Yes
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ConfirmationModal;