// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAtC9jwp4dBJG6KcdWM8z5iJa6DmXMq_BI",
    authDomain: "easy-manage-app-3ddca.firebaseapp.com",
    projectId: "easy-manage-app-3ddca",
    storageBucket: "easy-manage-app-3ddca.appspot.com",
    messagingSenderId: "166345334925",
    appId: "1:166345334925:web:03e5ebc6158b997c9182c4",
    measurementId: "G-CEEPQXD4Y2"
  };
  
  export default firebaseConfig;
  