import firebaseConfig from './firebaseConfig';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import 'firebase/compat/functions';

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = firebase.auth();

// Initialize Analytics and get a reference to the service
const analytics = firebase.analytics();

// Firebase Firestore
const db = firebase.firestore();

const firebaseFunctions = firebase.functions();

export { auth, analytics, db, firebaseFunctions };
