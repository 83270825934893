import { Modal, Button, Icon, Form, Input, Menu, Segment } from 'semantic-ui-react';
import { useEffect, useState } from 'react';
import { CheckoutField, CheckoutProps, PaymentMethod, defaultCheckoutState, getTotal } from '../models/OrderDetail.model';

function CheckoutModal({ checkoutState, orderDetailState, onCharge, clearCheckoutnModal }: CheckoutProps) {

  const [checkoutDetail, setCheckoutDetail] = useState(defaultCheckoutState);

  useEffect(() => {
    setCheckoutDetail(prevState => ({
      ...prevState,
      remainingBalance: getTotal(orderDetailState)
    }));
  }, [orderDetailState]);

  const handleCashClick = () => {
    setCheckoutDetail(prevState => ({
      ...prevState,
      activePayment: PaymentMethod.cash,
      amountEntered: prevState.cash
    }));
  }

  const handleCreditCardClick = () => {
    setCheckoutDetail(prevState => ({
      ...prevState,
      activePayment: PaymentMethod.credit,
      amountEntered: prevState.credit
    }));
  }

  const handleGiftCardClick = () => {
    setCheckoutDetail(prevState => ({
      ...prevState,
      activePayment: PaymentMethod.gift,
      amountEntered: prevState.gift
    }));
  }

  const handleModifyAmount = (e: any) => {
    setCheckoutDetail(prevState => ({
      ...prevState,
      amountEntered: Number(e.target.value),
      [prevState.activePayment]: Number(e.target.value)
    }));
    calculateBalance();
  };

  const handleModifyCreditTips = (e: any) => {
    setCheckoutDetail(prevState => ({
      ...prevState,
      creditTips: Number(e.target.value)
    }));
  }

  const calculateBalance = () => {
    setCheckoutDetail(prevState => ({
      ...prevState,
      paid: prevState.cash + prevState.credit + prevState.gift,
      remainingBalance: getTotal(orderDetailState) - prevState.cash - prevState.credit - prevState.gift
    }));
  }

  const handleCharge = () => {
    onCharge({
      cash: checkoutDetail.cash + checkoutDetail.remainingBalance,
      credit: checkoutDetail.credit,
      giftCard: checkoutDetail.gift,
      creditTips: checkoutDetail.creditTips
    });
    handleCancel();
  }

  const handleChargeTotal = () => {
    onCharge({
      cash: checkoutDetail.activePayment === PaymentMethod.cash? getTotal(orderDetailState) : 0,
      credit: checkoutDetail.activePayment === PaymentMethod.credit? getTotal(orderDetailState) : 0,
      giftCard: checkoutDetail.activePayment === PaymentMethod.gift? getTotal(orderDetailState) : 0,
      creditTips: 0
    });
    handleCancel();
  }

  const handleCancel = () => {
    clearCheckoutState();
    clearCheckoutnModal();
  }

  const handleClearClick = (value: CheckoutField) => {
    if (value === CheckoutField.amount) {
      handleModifyAmount({ target: { value: 0 } });
    } else if (value === CheckoutField.Tips) {
      setCheckoutDetail(prevState => ({
        ...prevState,
        creditTips: 0
      }));
    }
    calculateBalance();
  }

  const clearCheckoutState = () => {
    setCheckoutDetail({
      ...defaultCheckoutState,
      remainingBalance: getTotal(orderDetailState)
    });
  }

  const getBalanceLabel = () => {
    return hasRemainingBalance() ? "Remaining Balance" : "Change";
  }

  const hasRemainingBalance = () => {
    return checkoutDetail.remainingBalance >= 0;
  }

  return (
    <Modal
      size="small"
      open={checkoutState.modalOpen}
    >
      <Modal.Header>{checkoutState.modalType}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div>
            <h4 className="checkout-page__balance">Paid: ${checkoutDetail.paid}</h4>
            <h4 className={`checkout-page__balance ${hasRemainingBalance() ? "" : "checkout-page__color-red"}`}>{getBalanceLabel()}: ${Math.abs(checkoutDetail.remainingBalance).toFixed(2)}</h4>
          </div>
          <br />
          <div>
            <Menu pointing widths={3}>
              <Menu.Item
                name="Cash"
                color="green"
                active={checkoutDetail.activePayment === PaymentMethod.cash}
                onClick={() => handleCashClick()}
              >
                <Icon name="dollar" /> Cash
              </Menu.Item>
              <Menu.Item
                name="Credit"
                color="blue"
                active={checkoutDetail.activePayment === PaymentMethod.credit}
                onClick={() => handleCreditCardClick()}
              >
                <Icon name="credit card" /> Credit Card
              </Menu.Item>
              <Menu.Item
                name="Gift"
                color="orange"
                active={checkoutDetail.activePayment === PaymentMethod.gift}
                onClick={() => handleGiftCardClick()}
              >
                <Icon name="gift" /> Gift Card
              </Menu.Item>
            </Menu>
            {checkoutDetail.activePayment !== PaymentMethod.none && <Segment>
              <Form>
                <Form.Field className="checkout-page__amount">
                  <label>Amount</label>
                  <Input
                    className="checkout-page__input"
                    icon={<Icon name="delete" circular link onClick={() => handleClearClick(CheckoutField.amount)} />}
                    label={{ basic: true, content: "$" }}
                    labelPosition="left"
                    type="number"
                    value={checkoutDetail.amountEntered}
                    onChange={() => handleModifyAmount(event)}
                  />
                </Form.Field>
                <div className="checkout-page__amount-buttons">
                  {checkoutDetail.remainingBalance > 0 && <Button onClick={() => handleModifyAmount({ target: { value: checkoutDetail.remainingBalance } })}>{checkoutDetail.remainingBalance.toFixed(2)}</Button>}
                  <Button onClick={() => handleModifyAmount({ target: { value: 10 } })}>10</Button>
                  <Button onClick={() => handleModifyAmount({ target: { value: 20 } })}>20</Button>
                  <Button onClick={() => handleModifyAmount({ target: { value: 40 } })}>40</Button>
                  <Button onClick={() => handleModifyAmount({ target: { value: 50 } })}>50</Button>
                  <Button onClick={() => handleModifyAmount({ target: { value: 60 } })}>60</Button>
                  <Button onClick={() => handleModifyAmount({ target: { value: 70 } })}>70</Button>
                  <Button onClick={() => handleModifyAmount({ target: { value: 80 } })}>80</Button>
                  <Button onClick={() => handleModifyAmount({ target: { value: 100 } })}>100</Button>
                </div>

                {checkoutDetail.activePayment === PaymentMethod.credit && <Form.Field>
                  <label>Tips</label>
                  <Input
                    className="checkout-page__input"
                    icon={<Icon name="delete" circular link onClick={() => handleClearClick(CheckoutField.Tips)} />}
                    label={{ basic: true, content: "$" }}
                    labelPosition="left"
                    type="number"
                    value={checkoutDetail.creditTips}
                    onChange={() => handleModifyCreditTips(event)}
                  />
                </Form.Field>}
              </Form>
            </Segment>}
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
      <Button color={checkoutDetail.activePayment===PaymentMethod.cash?"green":checkoutDetail.activePayment===PaymentMethod.credit?"blue":"orange"} onClick={() => handleChargeTotal()} disabled={checkoutDetail.remainingBalance <= 0}>
           <Icon name="rocket"/> ${getTotal(orderDetailState)}
        </Button>
        <Button color="teal" onClick={() => handleCharge()} disabled={checkoutDetail.remainingBalance > 0}>
          <Icon name="dollar" /> Charge
        </Button>
        <Button color="grey" onClick={() => handleCancel()}>
          <Icon name="cancel" /> Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default CheckoutModal;