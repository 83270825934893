import { Action } from "../models/AppCommon.model";

// Action types
export const UPDATE_CLOCK_STATUS = "UPDATE_CLOCK_STATUS";

// Action creator
export function setClockStatus(value: any): Action {
    return {
        type: UPDATE_CLOCK_STATUS,
        payload: { ...value }
    }
}