import { Action } from "../models/AppCommon.model";

// Action types
export const INCREASE_LOADER = "INCREASE_LOADER";
export const DECREASE_LOADER = "DECREASE_LOADER";

// Action creator
export function increaseLoader(): Action {
    return {
        type: INCREASE_LOADER,
        payload: null 
    }
}

export function decreaseLoader(): Action {
    return {
        type: DECREASE_LOADER,
        payload: null 
    }
}