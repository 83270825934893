import {  UPDATE_USER_INFO } from '../actions/Auth.action';
import { AuthAction, AuthState, initialAuthState } from '../models/Auth.model';

// Reducer
const authReducer = (state: AuthState = initialAuthState, action: AuthAction): AuthState => {
    switch (action.type) {
        case UPDATE_USER_INFO:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default authReducer