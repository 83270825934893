import moment from "moment";
import { ClockData, ClockTime, Employee, Role, groupByClockData } from "./Employees.model";
import { OrderStatus } from "./OrderDetail.model";
import { updateErrorMessage } from "../actions/APIResponse.action";
import { Dispatch } from "react";

export interface Action {
    type: string;
    payload: any;
}

export interface ModalConfig {
    modalOpen: boolean;
    modalType: string;
}

export interface FilterProps {
    showOrderStatus: boolean;
    showRefresh: boolean;
    refreshEvent?: () => void;
}

export interface FilterState {
    startDate: Date | null;
    endDate: Date | null;
    selectRange: DateRangeValues | any;
    selectOrderStatus: OrderStatus | any;
}

export interface ResponceSetup {
    showMessages: boolean;
    isErrorMessage: boolean;
    message: string;
}

export interface PageInitialLoad {
    sale: boolean;
    orders: boolean;
    settings: boolean;
    reports: boolean;
    employees: boolean;
}

export enum DateRangeValues {
    today = "today",
    yesterday = "yesterday",
    aMonth = "aMonth",
    aYear = "aYear",
    all = "all"
}

export enum FirebaseDatabaseNames {
    users = "users",
    employees = "employees",
    orders = "orders",
    categories = "categories",
    items = "items",
    email = "mail",
    stripCustomers = "strip-customers",
    stripProducts = "strip-products",
    stripCheckoutSession = "checkout_sessions",
    subscriptions = "subscriptions",
}

export enum FirebaseCommonFiledNames {
    userId = "userId",
    createTimestamp = "createTimestamp",
    status = "status"
}

export enum SubscriptionStatus {
    incomplete = "incomplete",
    incompleteExpired = "incomplete_expired",
    trialing = "trialing",
    active = "active",
    pastDue = "past_due",
    canceled = "canceled",
    unpaid = "unpaid",
    paused = "paused"
}

export const modalTitles: any = {
    addCategory: "Add Category",
    editCategory: "Edit Category",
    addCategoryItem: "Add Item",
    editCategoryItem: "Edit Item",
    addEmployee: "Add Employee",
    editEmployee: "Edit Employee",
    modifyVariablePrice: "Enter Price"
}

export const dateRangeOptions: any = [
    { key: DateRangeValues.today, value: DateRangeValues.today, text: "Today" },
    { key: DateRangeValues.yesterday, value: DateRangeValues.yesterday, text: "Yesterday" },
    { key: DateRangeValues.aMonth, value: DateRangeValues.aMonth, text: "A Month" },
    { key: DateRangeValues.aYear, value: DateRangeValues.aYear, text: "A Year" },
    { key: DateRangeValues.all, value: DateRangeValues.all, text: "All" },
]

export const orderStatusOptions: any = [
    { key: OrderStatus.open, value: OrderStatus.open, text: OrderStatus.open },
    { key: OrderStatus.printed, value: OrderStatus.printed, text: OrderStatus.printed },
    { key: OrderStatus.close, value: OrderStatus.close, text: OrderStatus.close },
    { key: OrderStatus.cancel, value: OrderStatus.cancel, text: OrderStatus.cancel },
    { key: OrderStatus.none, value: OrderStatus.none, text: "All" }
]

export const defaultPageInitialLoad: PageInitialLoad = {
    sale: false,
    orders: false,
    employees: false,
    reports: false,
    settings: false
}

export const getStartDay = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
export const getEndDay = (date: Date) => new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);

export const getDefaultFilters = (): FilterState => {
    return {
        startDate: getStartDay(new Date()),
        endDate: getEndDay(new Date()),
        selectRange: null,
        selectOrderStatus: null
    }
}

export const defaultResponceSetup: ResponceSetup = {
    showMessages: false,
    isErrorMessage: false,
    message: ""
}

export const isEmptyArray = (array: any[]): boolean => Array.isArray(array) && array.length === 0;
export const isManagerRole = (employee: Employee) => employee ? (employee.role === Role.manager || employee.role === Role.admin) : false;
export const isAdmin = (employee: Employee) => employee ? employee.role === Role.admin : false;
export const isLoggedInEmployee = (employee: Employee, loggedInEmployee: Employee) => employee ? employee.id === loggedInEmployee.id : false;
export const formatDate = (date: Date, format: string): string => moment(date).format(format);
export const sortByPropertyName = (data: any[], propertyName: string): any[] => data.sort((a: any, b: any) => a[propertyName].toLowerCase().localeCompare(b[propertyName].toLowerCase()));
export const filterEmployeeByActive = (employees: Employee[]) => employees.filter(employee => !employee.inactive);
export const setPrintContent = (content: string) => {
    const contentToPrint = document.querySelector(`#${content}`);
    if (contentToPrint) {
        const windowContent = '<html><head><title>Print</title></head><body>' +
            contentToPrint.innerHTML + '</body></html>';
        const printWin = window.open('', '', 'width=1000,height=600');
        if (printWin) {
            printWin.document.open();
            printWin.document.write(windowContent);
            printWin.document.close();
            printWin.print();
            setTimeout(() => {
                printWin?.close();
            }, 1000);
        } else {
            alert('Failed to open print window. Please try again.');
        }
    } else {
        alert('Content not found. Please try again.');
    }
}
export const calculateLastClockTime = (employee: Employee) => {
    const lastClockTime: ClockTime = {
        clockInTime: null,
        clockOutTime: null
    };
    const today = formatDate(new Date(), dateFormat);
    Object.entries(groupByClockData(employee, null)).forEach((entry: any) => {
        const [key, value] = entry;
        if (key === today) {
            const todayClockTimes = value.sort((preClockData: ClockData, nextClockData: ClockData) => {
                return preClockData.clockDateTime.getTime() - nextClockData.clockDateTime.getTime();
            });
            if (todayClockTimes.length === 1 && todayClockTimes[0].isClockIn) {
                lastClockTime.clockInTime = todayClockTimes[0].clockDateTime;
            } else if (todayClockTimes.length > 1) {
                const lastClockTimeData = todayClockTimes.slice(todayClockTimes.length - 2);
                if (lastClockTimeData[0].isClockIn) {
                    lastClockTime.clockInTime = lastClockTimeData[0].clockDateTime;
                } else {
                    lastClockTime.clockOutTime = lastClockTimeData[0].clockDateTime;
                }
                if (lastClockTimeData[1].isClockIn) {
                    lastClockTime.clockInTime = lastClockTimeData[1].clockDateTime;
                } else {
                    lastClockTime.clockOutTime = lastClockTimeData[1].clockDateTime;
                }
            }
        }
    });
    return lastClockTime;
}
export const internetCheck = (dispatch: Dispatch<any>) => {
    if (!navigator.onLine) {
        dispatch(updateErrorMessage("No internet connection! Please try again later."));
        return;
    }
}
export const getTwoDClockTimesArray = (value: any) => {
    const clockTimes = value.sort((preClockData: ClockData, nextClockData: ClockData) => {
        return preClockData.clockDateTime.getTime() - nextClockData.clockDateTime.getTime();
    });
    const twoDClockTimesArray = [];
    for (let i = 0; i < clockTimes.length; i += 2) {
        if (clockTimes[i] && clockTimes[i + 1]) {
            if (clockTimes[i].isClockIn && !clockTimes[i + 1].isClockIn) {
                twoDClockTimesArray.push([clockTimes[i], clockTimes[i + 1]]);
            } else if (!clockTimes[i].isClockIn && clockTimes[i + 1].isClockIn) {
                twoDClockTimesArray.push([{ isClockIn: true, clockDateTime: undefined }, clockTimes[i]]);
                i--;
            } else if (clockTimes[i].isClockIn && clockTimes[i + 1].isClockIn) {
                twoDClockTimesArray.push([clockTimes[i], { isClockIn: false, clockDateTime: undefined }]);
                i--;
            } else if (!clockTimes[i].isClockIn && !clockTimes[i + 1].isClockIn) {
                twoDClockTimesArray.push([{ isClockIn: true, clockDateTime: undefined }, clockTimes[i].isClockIn]);
                twoDClockTimesArray.push([{ isClockIn: true, clockDateTime: undefined }, clockTimes[i + 1].isClockIn]);
            }
        } else if (clockTimes[i] && !clockTimes[i + 1]) {
            twoDClockTimesArray.push([clockTimes[i], { isClockIn: false, clockDateTime: undefined }]);
        }

    }
    return twoDClockTimesArray;
}

export const dateFormat = "YYYY-MM-DD";
export const dateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const clockTimeFormat = "YYYY-MM-DDTHH:mm";
export const contactEmail = "easy.manage.info@gmail.com";
export const contactPhone = "(347)-438-5805";