import { FilterState, ModalConfig, dateFormat, formatDate, isEmptyArray, sortByPropertyName } from "./AppCommon.model";

export interface Employee {
    id?: string;
    userId: string;
    name: string;
    role: Role;
    pin: string;
    clockData: ClockData[];
    inactive?: boolean;
}

export interface ClockData {
    isClockIn: boolean;
    clockDateTime: Date | any;
}

export interface ClockTime {
    clockInTime: Date | null;
    clockOutTime: Date | null;
}

export enum EmployeeModalType {
    addEmployee = "addEmployee",
    editEmployee = "editEmployee"
}

export enum Role {
    admin = "Admin",
    manager = "Manager",
    employee = "Employee"
}

export interface EmployeeModalProps {
    employeeModalState: ModalConfig;
    employees: Employee[];
    currentSelectedEmployee: Employee;
    setCurrentSelectedEmployee: (employee: any) => void;
    clearEmployeeModal: () => void;
}

export const defaultEmployee: Employee = {
    userId: "",
    name: "",
    role: Role.employee,
    pin: "",
    clockData: []
}

export const roleOptions = [
    { key: Role.manager, text: Role.manager, value: Role.manager },
    { key: Role.employee, text: Role.employee, value: Role.employee },
];

export function groupByClockData(employee: Employee, filterState: FilterState | null): any {
    let clockData = employee.clockData;
    if (filterState) {
        clockData = clockData.filter((data: ClockData) => {
            const clockDateTime = new Date(data.clockDateTime);
            if (filterState.startDate && filterState.endDate) {
                return clockDateTime >= filterState.startDate && clockDateTime <= filterState.endDate;
            } else if (filterState.startDate && filterState.endDate === null) {
                return clockDateTime >= filterState.startDate;
            } else if (filterState.startDate === null && filterState.endDate) {
                return clockDateTime <= filterState.endDate;
            } else {
                return true;
            }
        });
    }

    return clockData.reduce((result, currentData) => {
        const clockDate: string = formatDate(currentData.clockDateTime, dateFormat);
        if (!result[clockDate]) {
            result[clockDate] = [];
        }
        result[clockDate].push(currentData);
        return result;
    }, {} as any);
}

export function getAllClockDataExceptSelectedDate(employee: Employee, filterState: FilterState | null): ClockData[] {
    let clockData: ClockData[] = [...employee.clockData];
    if (filterState) {
        clockData = clockData.filter((data: ClockData) => {
            const clockDateTime = new Date(data.clockDateTime);
            if (filterState.startDate && filterState.endDate) {
                return clockDateTime < filterState.startDate || clockDateTime > filterState.endDate;
            } else if (filterState.startDate && filterState.endDate === null) {
                return clockDateTime < filterState.startDate;
            } else if (filterState.startDate === null && filterState.endDate) {
                return clockDateTime > filterState.endDate;
            } else {
                return true;
            }
        });
    }

    return clockData;
}

export function groupByRole(employees: Employee[]): any {
    if (!isEmptyArray(employees)) {
        return employees.reduce((result, currentData) => {
            const role: string = currentData.role;
            if (!result[role]) {
                result[role] = [];
            }
            result[role].push(currentData);
            return result;
        }, {} as any);
    }
    return {};
}

export function sortByRole(employees: Employee[]): Employee[] {
    if (!isEmptyArray(employees)) {
        let sortedEmployees: Employee[] = [];
        const groupedEmployees = groupByRole(employees);
        if (groupedEmployees[Role.admin])
            sortedEmployees = sortedEmployees.concat(groupedEmployees[Role.admin]);
        if (groupedEmployees[Role.manager])
            sortedEmployees = sortedEmployees.concat(sortByPropertyName(groupedEmployees[Role.manager], "name"));
        if (groupedEmployees[Role.employee])
            sortedEmployees = sortedEmployees.concat(sortByPropertyName(groupedEmployees[Role.employee], "name"));
        return sortedEmployees;
    }
    return employees;
}