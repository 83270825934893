import React, { useState } from 'react';
import { Table, Button, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { ClockTime, Employee, EmployeeModalType, Role, defaultEmployee } from '../models/Employees.model';
import ConfirmationModal from '../modals/Confirmation.modal';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { deleteEmployee, editEmployee } from '../actions/Employees.action';
import { RootState } from '../reducers/root.reducer';
import EmployeeModal from '../modals/Employee.modal';
import { calculateLastClockTime, dateTimeFormat, formatDate, isAdmin, isLoggedInEmployee, isManagerRole } from '../models/AppCommon.model';
import PinPadModal from '../modals/PinPad.modal';
import { setClockStatus } from '../actions/ClockStatus.action';
import '../styles/Common.css';

const EmployeesComponent = () => {
  const dispatch = useDispatch();
  const employeeSelectData = (state: RootState) => ({
    employees: state.employees,
    loggedInEmployee: state.loggedInEmployee,
    clockStatus: state.clockStatus
  });
  const { employees, loggedInEmployee, clockStatus } = useSelector(employeeSelectData,shallowEqual);

  const [confirmationModalState, setConfirmationModalState] = useState({
    modalOpen: false,
    modalType: "Delete Employee Confirmation",
    modalContentMessage: "Are you sure you want to delete this employee?"
  });
  const [employeeModalState, setEmployeeModalState] = useState({
    modalOpen: false,
    modalType: "",
  });
  const [currentSelectedEmployee, setCurrentSelectedEmployee] = useState(defaultEmployee);
  const [pinPadModalState, setPinPadModalState] = useState({
    modalOpen: false,
    modalType: "",
    allowClose: true,
    isInvalidPin: false
  });

  const getLastClockTime = (employee: Employee) => {
    const lastClockTime: ClockTime = calculateLastClockTime(employee);

    return {
      clockInTime: lastClockTime.clockInTime ? formatDate(lastClockTime.clockInTime, dateTimeFormat) : "",
      clockOutTime: lastClockTime.clockOutTime ? formatDate(lastClockTime.clockOutTime, dateTimeFormat) : ""
    };
  }

  const handleClickInOutClock = (employee: Employee) => {
    setPinPadModalState(prevState => ({
      ...prevState,
      modalOpen: true
    }));
    setCurrentSelectedEmployee(employee);
  };

  const handleModifyEmployee = (employee: Employee) => {
    setEmployeeModalState({
      modalOpen: true,
      modalType: EmployeeModalType.editEmployee
    });
    setCurrentSelectedEmployee(employee);
  };

  const handleDeleteEmployee = (employee: Employee) => {
    setConfirmationModalState(prevState => ({
      ...prevState,
      modalOpen: true
    }));
    setCurrentSelectedEmployee(employee);
  };

  const handleAddEmployee = () => {
    setEmployeeModalState({
      modalOpen: true,
      modalType: EmployeeModalType.addEmployee
    });
    setCurrentSelectedEmployee(defaultEmployee);
  };

  const handleNoClick = () => {
    clearConfirmationModal();
  }

  const handleYesClick = () => {
    dispatch(deleteEmployee(currentSelectedEmployee));
    clearConfirmationModal();
  }

  const onPinEntered = (pin: string) => {
    if (pin === currentSelectedEmployee.pin) {
      dispatch(setClockStatus({
        [currentSelectedEmployee.id!]: !clockStatus[currentSelectedEmployee.id!]
      }));
      dispatch(editEmployee({
        ...currentSelectedEmployee,
        clockData: [
          ...currentSelectedEmployee.clockData,
          {
            isClockIn: !clockStatus[currentSelectedEmployee.id!],
            clockDateTime: new Date()
          }
        ]
      }));
      clearPinPadModal();
    } else {
      setPinPadModalState(prevState => ({
        ...prevState,
        isInvalidPin: true
      }));
    }
  }

  const clearConfirmationModal = () => {
    setConfirmationModalState(prevState => ({
      ...prevState,
      modalOpen: false
    }));
    setCurrentSelectedEmployee(defaultEmployee);
  }

  const clearEmployeeModal = () => {
    setEmployeeModalState(prevState => ({
      ...prevState,
      modalOpen: false
    }));
    setCurrentSelectedEmployee(defaultEmployee);
  }

  const clearPinPadModal = () => {
    setPinPadModalState(prevState => ({
      ...prevState,
      modalOpen: false,
      isInvalidPin: false
    }));
  }

  const checkHighLevelRole = (employee: Employee) => {
    return (loggedInEmployee.role === Role.manager && (employee.role !== Role.admin && employee.role !== Role.manager)) || loggedInEmployee.role === Role.admin;
  }

  const getPin = (employee: Employee) => {
    return isManagerRole(employee) ? "****" : employee.pin;
  }

  const getFilteredEmployees = () => {
    return employees.filter((employee: Employee) => (checkHighLevelRole(employee) || isLoggedInEmployee(employee, loggedInEmployee)) && !employee.inactive);
  }

  return (
    <div className="container-height-scroll-bar">
      <Table color="grey" celled>
        <Table.Header className="element-sticky">
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>PIN</Table.HeaderCell>
            <Table.HeaderCell>Last Clock In Time</Table.HeaderCell>
            <Table.HeaderCell>Last Clock Out Time</Table.HeaderCell>
            <Table.HeaderCell>Clock In / Clock Out</Table.HeaderCell>
            {isManagerRole(loggedInEmployee) && <Table.HeaderCell></Table.HeaderCell>}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {getFilteredEmployees().map((employee: Employee) => (
            <Table.Row key={employee.id}>
              <Table.Cell>{employee.name}</Table.Cell>
              <Table.Cell active>{employee.role}</Table.Cell>
              <Table.Cell warning>{getPin(employee)}</Table.Cell>
              <Table.Cell positive>{getLastClockTime(employee).clockInTime}</Table.Cell>
              <Table.Cell positive
              >{getLastClockTime(employee).clockOutTime}</Table.Cell>
              <Table.Cell>
                <Button onClick={() => handleClickInOutClock(employee)} color={clockStatus[employee.id!] ? "red" : "green"} disabled={isAdmin(employee)}>
                  <Icon name={clockStatus[employee.id!] ? "clock" : "check"} />
                  {clockStatus[employee.id!] ? "Clock Out" : "Clock In"}
                </Button>
              </Table.Cell>
              {isManagerRole(loggedInEmployee) && <Table.Cell>
                <Button onClick={() => handleModifyEmployee(employee)} color="orange">
                  <Icon name="edit" />
                  Edit
                </Button>
                <Button onClick={() => handleDeleteEmployee(employee)} color="red" disabled={isLoggedInEmployee(employee, loggedInEmployee)}>
                  <Icon name="trash" />
                  Delete
                </Button>
              </Table.Cell>}
            </Table.Row>
          ))}
        </Table.Body>

        {isManagerRole(loggedInEmployee) && <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan="7">
              <Button
                floated="right"
                icon
                labelPosition="left"
                color="teal"
                onClick={() => handleAddEmployee()}
              >
                <Icon name="add" />
                Add New Employee
              </Button>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>}
      </Table>

      <EmployeeModal
        employeeModalState={employeeModalState}
        employees={employees}
        currentSelectedEmployee={currentSelectedEmployee}
        setCurrentSelectedEmployee={setCurrentSelectedEmployee}
        clearEmployeeModal={clearEmployeeModal}
      />

      <ConfirmationModal
        confirmationState={confirmationModalState}
        handleNoClick={handleNoClick}
        handleYesClick={handleYesClick}
      />

      <PinPadModal
        pinPadModalState={pinPadModalState}
        setPinPadModalState={setPinPadModalState}
        clearPinPadModal={clearPinPadModal}
        onPinEntered={onPinEntered}
      />
    </div>
  );
};

export default EmployeesComponent;
