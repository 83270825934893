import { Employee, defaultEmployee } from "../models/Employees.model";
import { Action } from "../models/AppCommon.model";
import { UPDATE_LOGGED_IN_EMPLOYEE } from "../actions/LoggedInEmployee.action";

const loggedInEmployeeReducer = (state: Employee = {...defaultEmployee}, action: Action): Employee => {
    switch (action.type) {
        case UPDATE_LOGGED_IN_EMPLOYEE:
            return { ...action.payload };
        default:
            return state;
    }
};

export default loggedInEmployeeReducer