import { Segment, Grid, Icon } from 'semantic-ui-react';
import '../styles/Orders.css';
import '../styles/Common.css';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from '../reducers/root.reducer';
import OrderDetailComponent from './OrderDetail';
import { OrderDetail, OrderStatus, getTotal } from '../models/OrderDetail.model';
import { OrdersProps, groupByEmployeeId } from '../models/Orders.model';
import { updateOrderDetail } from '../actions/OrderDetail.action';
import { dateTimeFormat, formatDate, isManagerRole } from '../models/AppCommon.model';
import FilterComponent from './Filter';
import { getOrders } from '../actions/Orders.action';

function OrdersComponent({ isEditOrder, setIsEditOrder, setActiveMenuItem }: OrdersProps) {
  const dispatch = useDispatch();

  const ordersSelectData = (state: RootState) => ({
    orders: state.orders,
    orderDetail: state.orderDetail,
    loggedInEmployee: state.loggedInEmployee,
    employees: state.employees,
    authState: state.auth,
    filterState: state.filter
  });
  const { orders, orderDetail, loggedInEmployee, employees, authState, filterState } = useSelector(ordersSelectData, shallowEqual);

  const handleOrderClick = (order: OrderDetail) => {
    dispatch(updateOrderDetail(order));
  };

  const handleRefresh = () => {
    dispatch(getOrders(authState, filterState, employees, true));
  };

  const getStatusColor = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.open:
        return "status-open-color";
      case OrderStatus.close:
        return "status-close-color";
      case OrderStatus.cancel:
        return "status-cancel-color";
      case OrderStatus.printed:
        return "status-printed-color";
    }
  };

  const renderSegments = (orderGroup: OrderDetail[]) => {
    return orderGroup.map((order: OrderDetail) => {
      return <Grid.Column key={order.id} width={4} className="orders-page__segment-column">
        <Segment
          className={`orders-page__order ${orderDetail.id === order.id ? "orders-page__order-selected" : ""}`}
          onClick={() => handleOrderClick(order)}
        >
          <div className="orders-page__price">${getTotal(order).toFixed(2)}</div>
          <div className="orders-page__date-time"><Icon name="clock outline" />{formatDate(order.createTimestamp!, dateTimeFormat)}</div>
          <div className={`orders-page__status ${getStatusColor(order.status!)}`}>{order.status}</div>
        </Segment>
      </Grid.Column>
    });
  };

  const renderSegmentsRows = (orderGroup: OrderDetail[]) => {
    const rows: any = [];
    for (let i = 0; i < orderGroup.length; i += 4) {
      rows.push(
        <Grid.Row key={i} className="orders-page__segment-row">
          {renderSegments(orderGroup).slice(i, i + 4)}
        </Grid.Row>
      );
    }
    return rows;
  };

  return (
    <Grid>
      <Grid.Column width={10}>
        <Segment className="container-height-scroll-bar">
          <FilterComponent showOrderStatus={true} showRefresh={true} refreshEvent={handleRefresh} />
          {orders && Object.entries(groupByEmployeeId(orders)).map((entry: any) => {
            const employeeId: string = entry[0];
            const employee = employees.find((employee) => employee.id === employeeId);
            if (isManagerRole(loggedInEmployee) || employeeId === loggedInEmployee.id) {
              const orderGroup: OrderDetail[] = entry[1].sort((preOrder: OrderDetail, nextOrder: OrderDetail) => {
                return preOrder.createTimestamp!.getTime() - nextOrder.createTimestamp!.getTime();
              });
              return <Segment.Group key={employeeId} size="tiny">
                <Segment className="orders-page__padding-top-bottom" inverted tertiary color="black">{employee!.name}</Segment>
                <Segment secondary>
                  <Grid>
                    {renderSegmentsRows(orderGroup)}
                  </Grid>
                </Segment>
              </Segment.Group>
            } else {
              return null;
            }
          })}
        </Segment>
      </Grid.Column>
      <Grid.Column stretched width={6}>
        <Segment secondary className="container-height-scroll-bar">
          <OrderDetailComponent
            orderDetailState={orderDetail}
            isSalePage={false}
            isEditOrder={isEditOrder}
            setIsEditOrder={setIsEditOrder}
            setActiveMenuItem={setActiveMenuItem}
          />
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

export default OrdersComponent;