import { ADD_EMPLOYEE, EDIT_EMPLOYEE, GET_EMPLOYEES } from "../actions/Employees.action";
import { Action } from "../models/AppCommon.model";
import { Employee, sortByRole } from "../models/Employees.model";

const employeeReducer = (state: Employee[] = [], action: Action): Employee[] => {
    switch (action.type) {
        case GET_EMPLOYEES:
            return sortByRole([...action.payload]);
        case ADD_EMPLOYEE:
            return sortByRole([...state, action.payload]);
        case EDIT_EMPLOYEE:
            return state.map((employee: Employee) => employee.id === action.payload.id ? action.payload : employee);
        default:
            return state;
    }
};

export default employeeReducer