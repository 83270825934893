import React from 'react';
import { connect } from 'react-redux';
import { getUserById, updateUserInfo, updateUserRef } from '../actions/Auth.action';
import { Form, Input, Button, Grid, Message, Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import '../styles/Auth.css';
import logo from '../images/logo.png';
import { AuthActionCreators, AuthLocalState, AuthState, AuthStateValue, emailPattern, errorMessages, initialAuthState, phoneNumberPattern } from '../models/Auth.model';
import { RootState } from '../reducers/root.reducer';
import { auth, db } from '../firebase/firebaseService';
import { defaultSettingState } from '../models/Settings.model';
import { FirebaseDatabaseNames, contactEmail, contactPhone } from '../models/AppCommon.model';
import { addEmployee } from '../actions/Employees.action';
import { Role } from '../models/Employees.model';

class AuthComponent extends React.Component<AuthStateValue & AuthActionCreators, AuthLocalState> {
  private errorMessage = "";
  private unsubscribe: any;

  constructor(props: AuthStateValue & AuthActionCreators) {
    super(props)
    this.state = {
      ...initialAuthState,
      password: "",
      confirmPassword: "",
      isRegister: false,
      emailValidate: { errorMessage: errorMessages.emailErrorMessage, isNotValid: false },
      passwordValidate: { errorMessage: errorMessages.passwordErrorMessage, isNotValid: false },
      confirmPasswordValidate: { errorMessage: errorMessages.confirmPasswordErrorMessage, isNotValid: false },
      phoneValidate: { errorMessage: errorMessages.phoneErrorMessage, isNotValid: false },
      loginValidate: { errorMessage: errorMessages.loginErrorMessage, isNotValid: false },
      registerValidate: { errorMessage: errorMessages.registerErrorMessage, isNotValid: false },
      verifyEmailValidate: { errorMessage: errorMessages.verifyEmailErrorMessage, isNotValid: false },
    };
  }

  componentDidMount() {
    // // Set up the listener to watch for changes in the authentication state
    this.unsubscribe = auth.onAuthStateChanged(user => {
      if (user?.emailVerified) {
        this.getUserInfoFromDbAndLogin(user);
      }
    });
  }

  componentWillUnmount() {
    // // Clean up the listener when the component unmounts
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  }

  showError(): boolean {
    if (this.state.emailValidate.isNotValid) {
      this.errorMessage = this.state.emailValidate.errorMessage;
      return true;
    } else if (this.state.passwordValidate.isNotValid) {
      this.errorMessage = this.state.passwordValidate.errorMessage;
      return true;
    } else if (this.state.loginValidate.isNotValid) {
      this.errorMessage = this.state.loginValidate.errorMessage;
      return true;
    } else if (this.state.isRegister && this.state.confirmPasswordValidate.isNotValid) {
      this.errorMessage = this.state.confirmPasswordValidate.errorMessage;
      return true;
    } else if (this.state.isRegister && this.state.phoneValidate.isNotValid) {
      this.errorMessage = this.state.phoneValidate.errorMessage;
      return true;
    } else if (this.state.isRegister && this.state.registerValidate.isNotValid) {
      this.errorMessage = this.state.registerValidate.errorMessage;
      return true;
    } else if (this.state.verifyEmailValidate.isNotValid) {
      this.errorMessage = this.state.verifyEmailValidate.errorMessage;
      return true;
    } else {
      this.errorMessage = "";
      return false;
    }
  }

  handleUsernameChange = (e: any) => {
    this.setState({
      email: e.target.value
    });
  };

  handlePasswordChange = (e: any) => {
    this.setState({
      password: e.target.value
    });
  };

  handleConfirmPasswordChange = (e: any) => {
    this.setState({
      confirmPassword: e.target.value
    });
  };

  handlePhoneNumberChange = (e: any) => {
    this.setState({
      phone: e.target.value
    });
  };

  handleLogin = () => {
    if (this.isEmailValid() && this.isPasswordValid()) {
      this.onLogin(this.state.email, this.state.password!);
    }
  };

  handleRegister = () => {
    this.setState({
      isRegister: true,
      loginValidate: { ...this.state.loginValidate, isNotValid: false },
      emailValidate: { ...this.state.emailValidate, isNotValid: false },
      passwordValidate: { ...this.state.passwordValidate, isNotValid: false },
      verifyEmailValidate: { ...this.state.verifyEmailValidate, isNotValid: false },
    });
  };

  handleRegisterSubmit = () => {
    if (this.isEmailValid() && this.isPasswordValid() && this.isConfirmPasswordValid() && this.isPhoneNumberValid()) {
      this.onRegister(this.state.email, this.state.password!, this.state.phone!);
    }
  };

  handleRegisterCancel = () => {
    this.clearUserInfo();
  };

  render() {
    return (
      <div className="auth-page">
        <div className="auth-page__grid-block">
          <Grid divided="vertically">
            <Grid.Row columns={3}>
              <Grid.Column></Grid.Column>
              <Grid.Column>
                <div>
                  <div className="auth-page__logo-block">
                    <Image src={logo} size="medium" />
                  </div>
                  <div className="auth-page__form-block">
                    {this.props.apiResponseSetup.showMessages && <Message color={this.props.apiResponseSetup.isErrorMessage ? "red" : "green"}>{this.props.apiResponseSetup.message}</Message>}
                    {this.showError() && <Message negative>
                      <Message.Header>{this.errorMessage}</Message.Header>
                    </Message>}
                    <Form>
                      <Form.Field>
                        <Input
                          type="text"
                          value={this.state.email}
                          onChange={this.handleUsernameChange}
                          icon="user"
                          iconPosition="left"
                          placeholder="Email"
                          autoComplete="on"
                          required
                        />
                      </Form.Field>
                      <Form.Field>
                        <Input
                          type="password"
                          value={this.state.password}
                          onChange={this.handlePasswordChange}
                          icon="lock"
                          iconPosition="left"
                          placeholder="Password"
                          autoComplete="on"
                          required
                        />
                      </Form.Field>

                      {this.state.isRegister &&
                        <div>
                          <Form.Field>
                            <Input
                              type="password"
                              value={this.state.confirmPassword}
                              onChange={this.handleConfirmPasswordChange}
                              icon="lock"
                              iconPosition="left"
                              placeholder="Confirm Password"
                              required
                            />
                          </Form.Field>
                          <Form.Field>
                            <Input
                              type="tel"
                              value={this.state.phone}
                              onChange={this.handlePhoneNumberChange}
                              icon="phone"
                              iconPosition="left"
                              placeholder="Phone Number"
                              autoComplete="on"
                              required
                            />
                          </Form.Field>
                          <div className="auth-page__center-align auth-page__margin-top-30">
                            <Button className="auth-page__register-button" color="teal" onClick={this.handleRegisterSubmit}>Register</Button>
                            <Button className="auth-page__register-button" color="grey" onClick={this.handleRegisterCancel}>Cancel</Button>
                          </div>
                        </div>
                      }

                      {!this.state.isRegister &&
                        <div>
                          <div className="auth-page__center-align auth-page__margin-top-30">
                            <Button className="auth-page__login-button" color="teal" onClick={this.handleLogin}>Login</Button>
                          </div>
                          <div className="auth-page__center-align auth-page__margin-top-30 auth-page__pointer">
                            <a className="auth-page__login-ancher" onClick={this.handleRegister}>Don't have an account?</a>
                          </div>
                        </div>
                      }
                    </Form>
                    <div className="auth-page__business-info"><b>Contact Us:</b>&nbsp; &nbsp; {contactEmail}&nbsp; &nbsp; {contactPhone}</div>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  }

  private onLogin(email: string, password: string): void {
    auth.signInWithEmailAndPassword(email, password).then(userCredential => {
      const user = userCredential.user!;
      // Check if the user's email is verified
      if (user.emailVerified) {
        this.getUserInfoFromDbAndLogin(user);
      } else {
        // Email is not verified, notify the user to verify their email.
        user.sendEmailVerification()
        this.setState({
          verifyEmailValidate: { ...this.state.verifyEmailValidate, isNotValid: true }
        });
      }
    }).catch(() => {
      this.setState({
        loginValidate: { ...this.state.loginValidate, isNotValid: true }
      });
    });
  }

  private getUserInfoFromDbAndLogin(user: any) {
    this.props.getUserById(user);
  }

  private onRegister(email: string, password: string, phone: string): void {
    auth.createUserWithEmailAndPassword(email, password).then((userCredential) => {
      const user = userCredential.user!;
      this.props.updateUserRef({
        id: user.uid,
        email: email,
        phone: phone,
        businessName: "",
        settings: defaultSettingState,
        isDemo: false,
        isSubscribed: false
      });
      //add default admin account
      this.props.addEmployee({
        userId: user.uid,
        name: "Admin",
        role: Role.admin,
        pin: "1234",
        clockData: []
      });
      //send notification email
      db.collection(FirebaseDatabaseNames.email).add({
        to: email,
        message: {
          subject: "Welcome to Easy Manage",
          html: `Welcome to Easy Manage. Your Admin account has been created. Please use PIN 1234 to setup your account.`
        }
      });
      this.clearUserInfo();
      //keep email and password
      this.setState(prevState => ({
        ...prevState,
        email: email,
        password: password,
        businessName: ""
      }));
    }).catch((error) => {
      this.setState({
        registerValidate: { errorMessage: error.message, isNotValid: true }
      });
    });
  }

  private isEmailValid(): boolean {
    const emailNotValid: boolean = (!this.state.email || (this.state.email && !this.state.email.match(emailPattern))) ? true : false;
    this.setState({
      emailValidate: { ...this.state.emailValidate, isNotValid: emailNotValid }
    });
    return !emailNotValid;
  }

  private isPasswordValid(): boolean {
    const passwordNotValid: boolean = (!this.state.password || (this.state.password && this.state.password.length < 8)) ? true : false;
    this.setState({
      passwordValidate: { ...this.state.passwordValidate, isNotValid: passwordNotValid }
    });
    return !passwordNotValid;
  }

  private isConfirmPasswordValid(): boolean {
    const confirmPasswordNotValid: boolean = (!this.state.confirmPassword || (this.state.confirmPassword && this.state.password !== this.state.confirmPassword)) ? true : false;
    this.setState({
      confirmPasswordValidate: { ...this.state.confirmPasswordValidate, isNotValid: confirmPasswordNotValid }
    });
    return !confirmPasswordNotValid;
  }

  private isPhoneNumberValid(): boolean {
    const phoneNumberNotValid: boolean = (!this.state.phone || (this.state.phone && !this.state.phone.match(phoneNumberPattern))) ? true : false;
    this.setState({
      phoneValidate: { ...this.state.phoneValidate, isNotValid: phoneNumberNotValid }
    });
    return !phoneNumberNotValid;
  }

  private clearUserInfo(): void {
    this.props.updateUserInfo(initialAuthState);
    this.setState({
      ...initialAuthState,
      password: "",
      confirmPassword: "",
      businessName: "",
      isRegister: false,
      emailValidate: { ...this.state.emailValidate, isNotValid: false },
      passwordValidate: { ...this.state.passwordValidate, isNotValid: false },
      confirmPasswordValidate: { ...this.state.confirmPasswordValidate, isNotValid: false },
      phoneValidate: { ...this.state.phoneValidate, isNotValid: false },
      loginValidate: { ...this.state.loginValidate, isNotValid: false },
      registerValidate: { errorMessage: errorMessages.registerErrorMessage, isNotValid: false }
    });
  }
}

function mapStateToProps({ auth, apiResponseSetup }: RootState): AuthStateValue {
  return { auth: { ...auth }, apiResponseSetup: { ...apiResponseSetup } }
}

const mapDispatchToProps: AuthActionCreators = {
  updateUserInfo: updateUserInfo,
  updateUserRef: updateUserRef,
  getUserById: getUserById,
  addEmployee: addEmployee,
}

export default connect<AuthStateValue, AuthActionCreators, any, any>(mapStateToProps, mapDispatchToProps)(AuthComponent)
