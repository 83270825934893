import { Table, Button, Icon } from 'semantic-ui-react';
import '../styles/Reports.css';
import '../styles/Common.css';
import { RootState } from '../reducers/root.reducer';
import { useSelector } from 'react-redux';
import { OrderDetail, calculatecommissionsPerOrder, getTotal } from '../models/OrderDetail.model';
import { useEffect, useRef, useState } from 'react';
import { groupByEmployeeId } from '../models/Orders.model';
import { ReportsCharState, ReportsExport, ReportsState, ReportsTotalCountMap, SwitchType, defaultReportsTotalCountMap, exportToExcel, groupByTimestamp } from '../models/Reports.model';
import { ClockData, Role, groupByClockData } from '../models/Employees.model';
import FilterComponent from './Filter';
import ReportsChart from '../charts/ReportsChart';
import { dateFormat, formatDate, getTwoDClockTimesArray, isLoggedInEmployee, isManagerRole, setPrintContent } from "../models/AppCommon.model";
import { PrintReportComponent } from './PrintReport';

function ReportsComponent() {
  const orders = useSelector((state: RootState) => state.orders);
  const employees = useSelector((state: RootState) => state.employees);
  const filterState = useSelector((state: RootState) => state.filter);
  const loggedInEmployee = useSelector((state: RootState) => state.loggedInEmployee);

  const [reportsSwitch, setReportsSwitch] = useState<SwitchType>(SwitchType.table);
  const [reportsCharState, setReportsCharState] = useState<ReportsCharState>({
    categories: [],
    series: []
  });
  const reportsTotalCountMap = useRef<ReportsTotalCountMap | any>(defaultReportsTotalCountMap);
  const reportsExportData = useRef<ReportsExport[]>([]);

  useEffect(() => {
    const groupByEmployeeIdArray = groupByEmployeeId(orders);
    const filteredEmployees = employees.filter(employee => Object.keys(groupByClockData(employee, filterState)).length !== 0 || orders.filter(order => order.employeeId === employee.id || (order.employee && order.employee.id) === employee.id).length !== 0);
    const newReportState: ReportsState[] = [
      ...filteredEmployees.filter(employee => employee.role !== Role.admin && (isManagerRole(loggedInEmployee) || isLoggedInEmployee(employee, loggedInEmployee))).map(employee => {
        if (employee.id! in groupByEmployeeIdArray) {
          return {
            ...employee,
            orders: groupByEmployeeIdArray[employee.id!]
          }
        }
        return {
          ...employee,
          orders: []
        };
      })
    ]
    calculateReportsExportData(newReportState);
    calculateCharState();
  }, [orders, employees, filterState]);

  const calculateCharState = () => {
    const groupByTimestampArray = groupByTimestamp(orders.sort((preOrder: OrderDetail, nextOrder: OrderDetail) => {
      return formatDate(preOrder.createTimestamp!, dateFormat).localeCompare(formatDate(nextOrder.createTimestamp!, dateFormat));
    }));
    setReportsCharState({
      categories: [...Object.keys(groupByTimestampArray)],
      series: [{
        name: "Sales",
        data: [...(Object.values(groupByTimestampArray) as any).map((orders: OrderDetail[]) => {
          return orders.reduce((acc: number, orders: OrderDetail) => acc + getTotal(orders), 0);
        })]
      }]
    });
  }


  const calculateReportsExportData = (curreentReportState: ReportsState[]) => {
    reportsExportData.current = [];
    clearReportsTotalCountMap();
    curreentReportState.map((employeeDetail: ReportsState) => {
      const exportData: ReportsExport = {
        name: employeeDetail.name,
        hours: getNumberOfHours(employeeDetail),
        days: getNumberOfDays(employeeDetail),
        orders: getNumberOfOrders(employeeDetail.orders),
        commissions: getSumOfCommissions(employeeDetail.orders),
        creditTips: getSumOfSales(employeeDetail.orders, "creditTips"),
        cash: getSumOfSales(employeeDetail.orders, "cashAmount"),
        creditCard: getSumOfSales(employeeDetail.orders, "creditAmount"),
        giftCard: getSumOfSales(employeeDetail.orders, "giftCardAmount"),
        overallSale: getSumOfOverallSales(employeeDetail.orders)
      };
      reportsExportData.current.push(exportData);
    });
    reportsExportData.current.push({
      name: "",
      hours: getSumOfTotalCount(reportsTotalCountMap.current.hours),
      days: getSumOfTotalCount(reportsTotalCountMap.current.days),
      orders: getSumOfTotalCount(reportsTotalCountMap.current.orders),
      commissions: getSumOfTotalCount(reportsTotalCountMap.current.commissions),
      creditTips: getSumOfTotalCount(reportsTotalCountMap.current.creditTips),
      cash: getSumOfTotalCount(reportsTotalCountMap.current.cashAmount),
      creditCard: getSumOfTotalCount(reportsTotalCountMap.current.creditAmount),
      giftCard: getSumOfTotalCount(reportsTotalCountMap.current.giftCardAmount),
      overallSale: getSumOfTotalCount(reportsTotalCountMap.current.totalSaleAmount)
    });
  }

  const fetchNumberOfHoursAndDays = (employeeInfo: ReportsState) => {
    let numberOfHours = 0;
    let numberOfDays = 0;
    let newDate = true;
    Object.values(groupByClockData(employeeInfo, filterState)).forEach((value: any) => {
      newDate = true;
      const twoDClockTimesArray = getTwoDClockTimesArray(value);
      twoDClockTimesArray.forEach((clockData: ClockData[]) => {
        if (clockData.length === 2 && (clockData[0] && clockData[0].clockDateTime) && (clockData[1] && clockData[1].clockDateTime)) {
          numberOfHours = numberOfHours + (clockData[1].clockDateTime.getTime() - clockData[0].clockDateTime.getTime()) / 1000 / 60 / 60;
          if (newDate) {
            numberOfDays = numberOfDays + 1;
            newDate = false;
          }
        }
      });
    });

    numberOfHours = Number(numberOfHours.toFixed(2));
    numberOfDays = Number(numberOfDays.toFixed(2));

    return { numberOfHours, numberOfDays };
  }

  const getNumberOfHours = (employeeInfo: ReportsState) => {
    const numberOfHours = fetchNumberOfHoursAndDays(employeeInfo).numberOfHours;
    reportsTotalCountMap.current.hours.push(numberOfHours);
    return numberOfHours;
  }

  const getNumberOfDays = (employeeInfo: ReportsState) => {
    const numberOfDays = fetchNumberOfHoursAndDays(employeeInfo).numberOfDays;
    reportsTotalCountMap.current.days.push(numberOfDays);
    return numberOfDays;
  }

  const getNumberOfOrders = (orderDetails: OrderDetail[]) => {
    const numberOfOrders: number = orderDetails.length;
    reportsTotalCountMap.current.orders.push(numberOfOrders);
    return numberOfOrders;
  }

  const getSumOfCommissions = (orderDetails: OrderDetail[]) => {
    const sumOfCommissions: number = orderDetails.reduce((acc, orderDetail: OrderDetail) => acc + calculatecommissionsPerOrder(orderDetail), 0);
    reportsTotalCountMap.current.commissions.push(sumOfCommissions);
    return sumOfCommissions;
  }

  const getSumOfSales = (orderDetails: OrderDetail[], value: string) => {
    const sumOfValues: number = orderDetails.reduce((acc, orderDetail: any) => acc + (orderDetail[value] ? orderDetail[value] : 0), 0);
    reportsTotalCountMap.current[value].push(sumOfValues);
    return sumOfValues;
  }

  const getSumOfOverallSales = (orderDetails: OrderDetail[]) => {
    const sumOfSales: number = orderDetails.reduce((acc, orderDetail: OrderDetail) => acc + getTotal(orderDetail), 0);
    reportsTotalCountMap.current.totalSaleAmount.push(sumOfSales);
    return sumOfSales;
  }

  const getSumOfTotalCount = (values: number[]) => {
    return values.reduce((acc, value: number) => acc + value, 0);
  }

  const handleExportClick = () => {
    exportToExcel(reportsExportData.current, "Sales Report.xlsx");
  }

  const handleSwitchClick = () => {
    if (reportsSwitch === SwitchType.table) {
      setReportsSwitch(SwitchType.chart);
    } else {
      setReportsSwitch(SwitchType.table);
    }
  }

  const handlePrint = () => {
    setPrintContent("report-print-content");
  };

  const clearReportsTotalCountMap = (): void => {
    reportsTotalCountMap.current.hours = [];
    reportsTotalCountMap.current.days = [];
    reportsTotalCountMap.current.orders = [];
    reportsTotalCountMap.current.commissions = [];
    reportsTotalCountMap.current.creditTips = [];
    reportsTotalCountMap.current.cashAmount = [];
    reportsTotalCountMap.current.creditAmount = [];
    reportsTotalCountMap.current.giftCardAmount = [];
    reportsTotalCountMap.current.totalSaleAmount = [];
  }

  return (
    <div className="container-height-scroll-bar">
      <div className="reports-page__filter-block">
        <div className="reports-page__date-filter">
          <FilterComponent showOrderStatus={false} showRefresh={false} />
        </div>
        <div className="reports-page__chart-export">
          <Button onClick={() => handlePrint()}>
            <Icon name="print" /> Print Report
          </Button>
          <div style={{ display: "none" }}><PrintReportComponent reportsExportData={reportsExportData} /></div>
          {isManagerRole(loggedInEmployee) && <Button onClick={() => handleExportClick()}>
            <Icon name="download" /> Export
          </Button>}
          {isManagerRole(loggedInEmployee) && <Button icon onClick={() => handleSwitchClick()}>
            {reportsSwitch === SwitchType.table && <Icon name="chart area" />}
            {reportsSwitch === SwitchType.chart && <Icon name="table" />}
          </Button>}
        </div>
      </div>

      {reportsSwitch === SwitchType.table && <Table color="grey" celled className="reports-page__table">
        <Table.Header className="element-sticky">
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Hours</Table.HeaderCell>
            <Table.HeaderCell>Days</Table.HeaderCell>
            <Table.HeaderCell>Orders</Table.HeaderCell>
            <Table.HeaderCell>Commissions</Table.HeaderCell>
            <Table.HeaderCell>Credit Card Tips</Table.HeaderCell>
            <Table.HeaderCell>Cash Sales</Table.HeaderCell>
            <Table.HeaderCell>Credit Card Sales</Table.HeaderCell>
            <Table.HeaderCell>Gift Card Sales</Table.HeaderCell>
            <Table.HeaderCell>Overall Sales</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {reportsExportData.current.map((exportData: ReportsExport, index: number) => {
            if (reportsExportData.current.length - 1 !== index) {
              return (
                <Table.Row key={index}>
                  <Table.Cell>{exportData.name}</Table.Cell>
                  <Table.Cell active>{exportData.hours.toFixed(2)}</Table.Cell>
                  <Table.Cell active>{exportData.days}</Table.Cell>
                  <Table.Cell active>{exportData.orders}</Table.Cell>
                  <Table.Cell warning textAlign="right">${exportData.commissions.toFixed(2)}</Table.Cell>
                  <Table.Cell warning textAlign="right">${exportData.creditTips.toFixed(2)}</Table.Cell>
                  <Table.Cell positive textAlign="right">${exportData.cash.toFixed(2)}</Table.Cell>
                  <Table.Cell positive textAlign="right">${exportData.creditCard.toFixed(2)}</Table.Cell>
                  <Table.Cell positive textAlign="right">${exportData.giftCard.toFixed(2)}</Table.Cell>
                  <Table.Cell positive textAlign="right">${exportData.overallSale.toFixed(2)}</Table.Cell>
                </Table.Row>
              )
            }
          })}
        </Table.Body>
        {isManagerRole(loggedInEmployee) && <Table.Footer>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>{getSumOfTotalCount(reportsTotalCountMap.current.hours).toFixed(2)}</Table.HeaderCell>
            <Table.HeaderCell>{getSumOfTotalCount(reportsTotalCountMap.current.days)}</Table.HeaderCell>
            <Table.HeaderCell>{getSumOfTotalCount(reportsTotalCountMap.current.orders)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">${getSumOfTotalCount(reportsTotalCountMap.current.commissions).toFixed(2)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">${getSumOfTotalCount(reportsTotalCountMap.current.creditTips).toFixed(2)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">${getSumOfTotalCount(reportsTotalCountMap.current.cashAmount).toFixed(2)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">${getSumOfTotalCount(reportsTotalCountMap.current.creditAmount).toFixed(2)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">${getSumOfTotalCount(reportsTotalCountMap.current.giftCardAmount).toFixed(2)}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">${getSumOfTotalCount(reportsTotalCountMap.current.totalSaleAmount).toFixed(2)}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>}
      </Table>}

      {reportsSwitch === SwitchType.chart && <div>
        <ReportsChart reportsCharState={reportsCharState} />
      </div>}
    </div>
  );
}

export default ReportsComponent;