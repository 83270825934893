import { GET_CATEGORIES, ADD_CATEGORY, EDIT_CATEGORY, DELETE_CATEGORY } from "../actions/Sale.action";
import { Category } from "../models/Sale.model";
import { Action, sortByPropertyName } from "../models/AppCommon.model";

const categoryReducer = (state: Category[] = [], action: Action): Category[] => {
    switch (action.type) {
        case GET_CATEGORIES:
            return [...action.payload];
        case ADD_CATEGORY:
            return sortByPropertyName([...state, action.payload], "name");
        case EDIT_CATEGORY:
            return sortByPropertyName(state.map((category: Category) => category.id === action.payload.id ? action.payload : category), "name");
        case DELETE_CATEGORY:
            return [...state.filter((category: Category) => category.id !== action.payload.id)];
        default:
            return state;
    }
};

export default categoryReducer