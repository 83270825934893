import { Action } from "../models/AppCommon.model";
import { UPDATE_CLOCK_STATUS } from "../actions/ClockStatus.action";

const clockStatusReducer = (state: any = {}, action: Action): any => {
    switch (action.type) {
        case UPDATE_CLOCK_STATUS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default clockStatusReducer