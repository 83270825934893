import { Modal, Button, Input, Form, Message, Icon } from 'semantic-ui-react';
import { Category, CategoryModalProps, SaleEnum } from '../models/Sale.model';
import { useState } from 'react';
import ConfirmationModal from './Confirmation.modal';
import { modalTitles } from '../models/AppCommon.model';

function CategoryModal({ categoryState, addCategory, editCategory, deleteCategory, handleModifyCategory, clearCategoryModal }: CategoryModalProps) {

  const [categoryModalState, setCategoryModalState] = useState({
    confirmation: {
      modalOpen: false,
      modalType: "Remove Category Confirmation",
      modalContentMessage: "Are you sure you want to remove this category?"
    },
    categoryNameValidation: {
      isNotValid: false,
      message: "Please provide a Category Name"
    },
    selectedCategory: {} as Category
  });

  const showModalRemoveItem = () => {
    return categoryState.modalType === SaleEnum.editCategory;
  }

  const handleSave = (category: Category) => {
    if (categoryState.modalValue?.name !== "") {
      if (categoryState.modalType === SaleEnum.addCategory) {
        addCategory(category);
      } else {
        editCategory(category);
      }
      clearCategoryModal();
      clearCategoryNameValidation();
    } else {
      setCategoryModalState(prevState => ({
        ...prevState,
        categoryNameValidation: {
          ...prevState.categoryNameValidation,
          isNotValid: true
        }
      }));
    }
  }

  const handleDelete = (category: Category) => {
    setCategoryModalState(prevState => ({
      ...prevState,
      confirmation: {
        ...prevState.confirmation,
        modalOpen: true
      },
      selectedCategory: category
    }));
  }

  const handleCancel = () => {
    clearCategoryModal();
    clearCategoryNameValidation();
  }

  const handleNoClick = () => {
    clearConfirmationModal();
  }

  const handleYesClick = () => {
    deleteCategory(categoryModalState.selectedCategory);
    clearCategoryModal();
    clearConfirmationModal();
    clearCategoryNameValidation();
  }

  const clearConfirmationModal = () => {
    setCategoryModalState(prevState => ({
      ...prevState,
      confirmation: {
        ...prevState.confirmation,
        modalOpen: false
      }
    }));
  }

  const clearCategoryNameValidation = () => {
    setCategoryModalState(prevState => ({
      ...prevState,
      categoryNameValidation: {
        ...prevState.categoryNameValidation,
        isNotValid: false
      }
    }));
  }

  const showError = () => {
    return categoryModalState.categoryNameValidation.isNotValid;
  }

  return (
    <div>
      <Modal
        size="small"
        open={categoryState.modalOpen}
      >
        <Modal.Header>{modalTitles[categoryState.modalType]}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {showError() && <Message negative>
              <Message.Header>{categoryModalState.categoryNameValidation.message}</Message.Header>
            </Message>}
            <Form>
              <Form.Field>
                <label>Category Name</label>
                <Input
                  type="text"
                  value={categoryState.modalValue && categoryState.modalValue.name}
                  onChange={handleModifyCategory}
                />
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="teal" onClick={() => handleSave(categoryState.modalValue!)}>
            <Icon name="save" />
            Save
          </Button>
          <Button onClick={() => handleCancel()}>
            <Icon name="cancel" />
            Cancel
          </Button>
          {showModalRemoveItem() && <Button negative onClick={() => handleDelete(categoryState.modalValue!)}>
            <Icon name="delete" />
            Remove Item
          </Button>}
        </Modal.Actions>
      </Modal>

      <ConfirmationModal
        confirmationState={categoryModalState.confirmation}
        handleNoClick={handleNoClick}
        handleYesClick={handleYesClick}
      />
    </div>
  );
}

export default CategoryModal;