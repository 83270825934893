import { PageInitialLoad } from "./AppCommon.model";

export interface SettingsProps {
    setPageInitialLoad: (value: PageInitialLoad) => void;
}

export interface SettingState {
    tax: number;
    printReceiptAfterCheckout: boolean;
    autoLogoutTime: number;
}

export enum SettingMenuItem {
    profile = "My Profile",
    tax = "Tax",
    printReceipt = "Print Receipt After Checkout",
    autoLogoutTime = "Auto Logout Time",
    subscription = "Subscription",
    signOut = "Sign Out"
}

export const defaultSettingState: SettingState = {
    tax: 0,
    printReceiptAfterCheckout: true,
    autoLogoutTime: 60000
}