import { Employee } from "../models/Employees.model";
import { FirebaseCommonFiledNames, FirebaseDatabaseNames, internetCheck } from "../models/AppCommon.model";
import { db } from "../firebase/firebaseService";
import { clearMessage, fetchData, updateErrorMessage } from "./APIResponse.action";
import { Dispatch } from "react";
import { AuthState } from "../models/Auth.model";
import { decreaseLoader, increaseLoader } from "./Loader.action";

// Action types
export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const ADD_EMPLOYEE = "ADD_EMPLOYEE";
export const EDIT_EMPLOYEE = "EDIT_EMPLOYEE";

// Action creator
export function getEmployees(authState: AuthState): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.employees).where(FirebaseCommonFiledNames.userId, "==", authState.id).get()
            .then(querySnapshot => {
                const employees: any[] = [];
                querySnapshot.forEach(doc => {
                    employees.push({ id: doc.id, ...doc.data(), clockData: doc.data().clockData.map((clock: any) => ({ ...clock, clockDateTime: clock.clockDateTime.toDate() })) });
                });
                dispatch(fetchData(GET_EMPLOYEES, [...employees]));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error getting employees: " + error.message));
                dispatch(fetchData(GET_EMPLOYEES, []));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function addEmployee(employee: Employee): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.employees).add(employee)
            .then(docRef => {
                dispatch(fetchData(ADD_EMPLOYEE, { id: docRef.id, ...employee }));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error adding employees: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function editEmployee(employee: Employee): any {
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.employees).doc(employee.id).update(employee)
            .then(() => {
                dispatch(fetchData(EDIT_EMPLOYEE, employee));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error editing employees: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}

export function deleteEmployee(employee: Employee): any {
    employee.inactive = true;
    return (dispatch: Dispatch<any>) => {
        dispatch(clearMessage());
        internetCheck(dispatch);
        dispatch(increaseLoader());
        db.collection(FirebaseDatabaseNames.employees).doc(employee.id).update(employee)
            .then(() => {
                dispatch(fetchData(EDIT_EMPLOYEE, employee));
            })
            .catch(error => {
                dispatch(updateErrorMessage("Error deleting employees: " + error.message));
            })
            .finally(() => {
                dispatch(decreaseLoader());
            });
    };
}