import { Employee } from "./Employees.model";
import { CategoryItem, patternEnum } from "./Sale.model";
import { ModalConfig } from "./AppCommon.model";
import { MenuItem } from "./Menu.model";

export interface OrderDetailProps {
    orderDetailState: OrderDetail;
    isSalePage: boolean;
    isEditOrder: boolean;
    setIsEditOrder: (value: boolean) => void;
    setActiveMenuItem?: (value: MenuItem) => void;
}

export interface CheckoutProps {
    checkoutState: ModalConfig;
    orderDetailState: OrderDetail;
    onCharge: (value: PaymentValues) => void;
    clearCheckoutnModal: () => void;
}

export interface OrderDetailItem extends CategoryItem {
    quantity: number;
}

export interface OrderDetail {
    id?: string;
    userId: string;
    items: OrderDetailItem[];
    tax: number;
    employee?: Employee; // should not use anymore
    employeeId?: string;
    cashAmount: number;
    creditAmount: number;
    giftCardAmount: number;
    creditTips: number;
    status: OrderStatus;
    discount?: number;
    createTimestamp?: Date;
}

export enum OrderStatus {
    open = "Open",
    close = "Close",
    cancel = "Cancel",
    printed = "Printed",
    none = "None",
}

export interface PaymentValues {
    cash: number;
    credit: number;
    giftCard: number;
    creditTips: number;
}

export interface CheckoutState {
    paid: number;
    remainingBalance: number;
    activePayment: PaymentMethod;
    amountEntered: number;
    cash: number;
    credit: number;
    gift: number;
    creditTips: number;
}

export interface SideMenuState {
    isOpen: boolean;
    discount: number;
    extraCharge: number;
    discountUnit: patternEnum;
}

export enum PaymentMethod {
    cash = "cash",
    credit = "credit",
    gift = "gift",
    none = ""
}

export enum SideMenuEnum {
    extraCharge = "extraCharge"
}

export enum CheckoutField {
    amount = "amount",
    Tips = "Tips"
}

export const defaultCheckoutState: CheckoutState = {
    paid: 0,
    remainingBalance: 0,
    activePayment: PaymentMethod.cash,
    amountEntered: 0,
    cash: 0,
    credit: 0,
    gift: 0,
    creditTips: 0
}

export const defaultSideMenuState: SideMenuState = {
    isOpen: false,
    discount: 0,
    extraCharge: 0,
    discountUnit: patternEnum.amount
}

export const defaultOrderDetail: OrderDetail = {
    userId: "",
    items: [],
    tax: 0,
    cashAmount: 0,
    creditAmount: 0,
    giftCardAmount: 0,
    creditTips: 0,
    status: OrderStatus.none,
    createTimestamp: undefined
}

export function getTotal(orderDetail: OrderDetail): number {
    const preTaxTotal = getPreTaxTotal(orderDetail);
    return Number(((orderDetail.discount? (preTaxTotal - orderDetail.discount) : preTaxTotal) * (1 + orderDetail.tax)).toFixed(2));
}

export function getPreTaxTotal(orderDetail: OrderDetail): number {
    return orderDetail.items.reduce((count, item) => count + item.itemPrice * item.quantity, 0);
}

export function calculatecommissionsPerOrder(orderDetail: OrderDetail): number {
    return orderDetail.items.reduce((count, item) => count + ((item.commissionAmount ? item.commissionAmount : 0) + (item.commissionPercentage ? item.itemPrice * (item.commissionPercentage / 100) : 0)) * item.quantity, 0);
}