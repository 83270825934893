import { OrderDetail, defaultOrderDetail } from "../models/OrderDetail.model";
import { Action } from "../models/AppCommon.model";
import { Employee } from "../models/Employees.model";
import { AuthState } from "../models/Auth.model";

// Action types
export const UPDATE_ORDER_DETAIL = "UPDATE_ORDER_DETAIL";

// Action creator
export function updateOrderDetail(order: OrderDetail): Action {
    return {
        type: UPDATE_ORDER_DETAIL,
        payload: { ...order }
    }
}

export function clearOrderDetail(employee: Employee, user: AuthState): Action {
    return {
        type: UPDATE_ORDER_DETAIL,
        payload: {
            ...defaultOrderDetail,
            employeeId: employee.id,
            tax: user.settings.tax,
            userId: user.id
        }
    }
}