import { MenuItem } from "./Menu.model";
import { OrderDetail } from "./OrderDetail.model";

export interface OrdersProps {
    isEditOrder: boolean;
    setIsEditOrder: (value: boolean) => void;
    setActiveMenuItem: (value: MenuItem) => void;
}

export function groupByEmployeeId(orders: OrderDetail[]): any {
    return orders.reduce((result, currentOrder) => {
        const employeeId: string = currentOrder.employeeId? currentOrder.employeeId : currentOrder.employee!.id!;
        if (!result[employeeId]) {
            result[employeeId] = [];
        }
        result[employeeId].push(currentOrder);
        return result;
    }, {} as any);
}