import { Action, FilterState, getDefaultFilters } from "../models/AppCommon.model";
import { UPDATE_FILTER } from "../actions/Filter.action";

const filterReducer = (state: FilterState = getDefaultFilters(), action: Action): any => {
    switch (action.type) {
        case UPDATE_FILTER:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default filterReducer